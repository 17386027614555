
          $font-path: "C:\Users\pc\Documents\new\spodiff-github\spotdif\src\assets\fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.TermsText {
    margin-bottom: 10px;
}
.credit-billing-div {
    display: flex;
    justify-content: space-between;
    gap: 40px;

    @media screen and (max-width: 991px) {
        margin-bottom: 30px;
        gap: 0;
    }

    @media screen and (max-width: 1199px) {
        flex-wrap: wrap;
    }

    .user-card-section {
        width: 460px;

        @media screen and (max-width: 1199px) {
            width: 350px;
        }

        @media screen and (max-width: 991px) {
            width: 100%;
        }

        h6 {
            color: $COLOR_BG;
            font-weight: 600;
            font-size: 20px;
            line-height: 23px;
            margin-bottom: 20px;
        }

        margin-bottom: 20px;

        .payment-ghost-button {
            background: transparent;
            margin: 15px auto 0;
            display: flex;
            color: $COLOR_BLUE-SHADE_1;
            text-transform: capitalize;
            font-size: 16px;
            font-weight: 500;
            border: 0;

            &:hover,
            &:visited,
            &:focus {
                background: transparent !important;
                color: $COLOR_BLUE-SHADE_1 !important;
            }

            svg {
                margin-left: 5px;
                width: 22px;
                height: 22px;
            }
        }
    }
}

.spotdif-payment-card {
    border-radius: 10px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.161);
    padding: 1.3rem;
    background: #fff;

    @media screen and (max-width: 480px) {
        padding: 1rem;
    }

    &-control {
        margin-top: 10px;

        span {
            font-size: 16px;
            font-weight: 600;
            color: $COLOR_BG;
        }

        span.material-icons-round {
            color: $COLOR_BG;
        }
    }
}

.user-card-deatils-section {
    width: calc(100% - 460px);

    @media screen and (max-width: 1366px) {
        width: calc(100% - 390px);
    }

    @media screen and (max-width: 991px) {
        width: 100%;
    }

    h6 {
        color: $COLOR_BG;
        font-size: 20px;
        line-height: 23px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .available-credit-details {
        align-items: center;
        border-radius: 10px;
        color: #000;
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        padding: 0px 0px 20px 0px;
        width: full;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        gap: 30px;
        // flex-wrap: wrap;
        @media screen and (max-width: 1480px) {
            // padding: 20px 30px;
        }

        @media screen and (max-width: 768px) {
            padding: 20px;
            flex-wrap: wrap;
        }

        .contact-credit {
            display: flex;
            align-items: end;
            justify-content: space-between;
            .heading_contact {
                display: flex;
                flex-direction: column;
                align-items: start;
            }
        }

        span:first-child,
        .contact-credit {
            line-height: 24px;
            position: relative;
            display: flex;
            background-color: white;
            border-radius: 12px;
            // padding-right: 22px;
            padding: 25px 20px;
            width: 100%;

            i {
                font-size: 35px;
                line-height: 36px;
                font-weight: 500;
                margin-top: 15px;
                font-style: normal;
                max-width: 120px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            @media screen and (max-width: 768px) {
                width: 100%;
            }

            // &:after {
            //     background-color: #d9d9d9;
            //     content: '';
            //     display: block;
            //     height: calc(100% - 12px);
            //     margin: 0;
            //     width: 1px;
            //     position: absolute;
            //     right: 0;
            //     top: 6px;

            //     @media screen and (max-width: 1480px) {
            //         display: none;
            //     }
            // }
        }

        span:nth-child(2) {
            font-size: 18px;
            line-height: 20px;
            display: flex;
            padding: 25px 60px;
            @media screen and (max-width: 1480px) {
                padding-left: 20px;
                width: 100%;
            }
        }

        span:nth-child(3) {
            font-size: 18px;
            line-height: 20px;
            display: flex;
            padding: 25px 60px;
            @media screen and (max-width: 1480px) {
                padding-left: 20px;
                width: 100%;
            }
        }

        .contact-credit {
            &:last-of-type {
                &::after {
                    height: 0;
                }
                // border: none;
                // i::after {

                // }
            }
        }

        span.credit-balance {
            margin-left: auto;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            text-align: right;
            @media screen and (max-width: 1480px) {
                text-align: left;
            }

            @media screen and (max-width: 1350px) and (max-width: 1480px) {
                width: 100%;
                justify-content: flex-start;
            }

            @media screen and (max-width: 767px) {
                width: 100%;
                justify-content: flex-start;
            }

            svg {
                width: 25px;
                margin-left: 10px;
            }
        }
    }
}

.payment-method-details {
    display: flex;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.161);
    font-size: 18px;
    padding: 20px 40px 10px;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 10;

    @media screen and (max-width: 1480px) {
        padding: 20px 30px;
    }

    @media screen and (max-width: 768px) {
        padding: 20px;
    }

    .button {
        margin-bottom: 10px;
        margin-right: 10px;
        min-width: 220px;

        @media screen and (max-width: 576px) {
            margin-right: 0;
            width: 100%;
        }
    }

    h6 {
        font-size: 18px;
        line-height: 20px;
        font-weight: 400;
        margin-bottom: 10px;
        padding-right: 25px;

        @media screen and (max-width: 768px) {
            width: 100%;
            padding-bottom: 10px;
        }
    }

    .button-solid {
        background-color: $COLOR_BLUE-SHADE_1;
        border: 1px solid $COLOR_BLUE-SHADE_1;
        color: #fff;
        margin-right: 15px;

        &:focus,
        &:active,
        &:hover {
            background-color: #052473 !important;
            color: #fff !important;
        }

        @media screen and (max-width: 576px) {
            margin-right: 0;
            width: 100%;
        }
    }

    .or {
        padding: 0 22px 10px 12px;

        @media screen and (max-width: 576px) {
            display: none;
        }
    }
}

.add-credit-details {
    display: flex;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.161);
    font-size: 18px;
    padding: 20px 40px;
    align-items: center;
    margin-top: 15px;
    flex-wrap: wrap;
    @media screen and (max-width: 1480px) {
        padding: 20px 30px;
    }
    &.add-credit-details-custom {
        box-shadow: none;
        margin-top: -10px;
        z-index: 1;
        position: relative;
        padding-top: 40px;
        padding-bottom: 35px;
        .credit-form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .credit-form-inline {
                & > div {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    font-size: 16px;
                    font-weight: 400;
                    color: $GRAY-1;
                }
                .spotdif-field {
                    width: 35% !important;
                    @media screen and (max-width: 1580px) {
                        width: 70% !important;
                    }
                    @media screen and (max-width: 1480px) {
                        width: 50% !important;
                    }
                }
            }
            & > form {
                width: 62%;
                display: block;
                @media screen and (max-width: 1580px) {
                    width: 50%;
                }
                @media screen and (max-width: 1480px) {
                    width: 100%;
                }
                button {
                    width: 100%;
                    margin-top: 15px;
                }
            }
            & > .vat-div {
                width: 38%;
                position: relative;
                padding-left: 60px;
                & > label {
                    width: 100%;
                    color: $GRAY-1;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 20px;
                    display: block;
                    margin-top: 20px;
                }
                @media screen and (max-width: 1580px) {
                    width: 50%;
                }
                @media screen and (max-width: 1480px) {
                    width: 100%;
                    padding-left: 0;
                    padding-top: 25px;
                }
                &:before {
                    content: '';
                    position: absolute;
                    left: 30px;
                    height: 100%;
                    width: 1px;
                    background: #cdcdd5;
                    top: 0;

                    @media screen and (max-width: 1480px) {
                        display: none;
                    }
                }
                .d-flex {
                    justify-content: space-between;
                    gap: 12px;
                    &:first-child {
                        margin-bottom: 25px;
                        @media screen and (max-width: 1480px) {
                            margin-bottom: 15px;
                        }
                    }
                    &:last-child {
                        p {
                            color: $COLOR_BG;
                        }
                    }
                    p {
                        font-size: 18px;
                        font-weight: 400;
                        color: $GRAY-1;
                        line-height: 20px;
                        width: 50%;
                        span {
                            font-size: 16px;
                            display: block;
                        }
                        &:last-child {
                            text-align: right;
                        }
                    }
                    h6 {
                        width: 50%;
                        text-align: right;
                        margin-bottom: 0;
                        font-weight: 500;
                        font-size: 20px;
                        color: $COLOR_BG;
                    }
                }
            }
        }
        h6 {
            font-weight: 400;
        }
        .spotdif-field {
            width: 100% !important;
            label {
                display: none;
            }
            input {
                text-align: center;
            }
        }
    }

    @media screen and (max-width: 1366px) {
        padding: 20px 30px;
    }

    @media screen and (max-width: 768px) {
        padding: 20px;
    }

    h6 {
        width: 100%;
    }

    .credit-form {
        width: 100%;
        form {
            display: flex;
            width: 100%;
            gap: 15px;

            @media screen and (max-width: 1280px) {
                flex-wrap: wrap;
            }

            input {
                border-radius: 30px;
            }
            .spotdif-field {
                @media screen and (max-width: 1280px) {
                    width: 100% !important;
                }
            }

            button {
                background-color: $COLOR_BG;
                border-color: $COLOR_BG;
                margin-top: 27px;
                width: 50%;
                @media screen and (max-width: 1280px) {
                    width: 100%;
                    margin-top: 0;
                }

                @media screen and (max-width: 576px) {
                    font-size: 14px;
                }
            }
        }
    }
}

.table-responsive {
    table {
        width: 100%; // Make the table responsive
        border-collapse: collapse; // Merge borders for a cleaner look

        thead {
            background-color: unset !important; // Light gray background for headers
            text-align: left;

            th {
                font-weight: 500;
                font-size: 18px;
                color: #565656;
                padding: 17px 36px !important;
            }
        }
    }
}

.table-heading {
    color: $COLOR_BG;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    padding-top: 30px;
    border-top: 1px solid rgba(0, 0, 50, 0.08);
}

.button-outline-blue.hover-text {
    &:hover {
        color: #ff4e02;
    }
}

button.btn-disabled:disabled {
    cursor: not-allowed;
    pointer-events: all;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.text-align-center {
    text-align: center;
}

.text-gray-100 {
    color: #808080;
}

.loading-spinner-big {
    text-align: center;
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    margin-left: 50%;
    /* Light grey */
    border-top: 5px solid #383636;
    /* Black */
    border-radius: 50%;
    margin-top: 1rem;
    animation: spinner 1.5s linear infinite;
}
.loading-spinner-small {
    text-align: center;
    width: 20px;
    height: 20px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #383636;
    border-radius: 50%;
    margin-top: 1rem;
    animation: spinner 1.5s linear infinite;
    margin-top: 0px;
}

.cross-image {
    width: 22px;
    padding-left: 0.4rem;
    fill: #808080;
    cursor: pointer;
}

.font-bold {
    font-weight: 500;
    font-size: 18px;
}

.cursor-pointer {
    cursor: pointer;
}

.display-flex {
    display: flex;
    flex-direction: column;
}

.d-flex {
    display: flex;
    flex-direction: row;
}

.vat-div {
    color: $GRAY-1;
    font-size: 1.2rem;
}
.contact-primary-user-message-container {
    border-radius: 12px;
    padding: 12px 24px;
    margin: 50px 0px;
    margin-top: 2rem;
    background-color: rgba(5, 36, 115, 0.1294117647);
    color: #052473;
    font-size: 1.1rem;
    font-weight: 600;
    border: 1px solid rgba(5, 36, 115, 0.1803921569);
}

// v3-css
.buy-contact-credits {
    display: flex;
    background: #fff;
    // width: 910px;
    min-height: 408px;
    border-radius: 10px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.161);
    font-size: 18px;
    padding: 24px;
    gap: 40px;

    .contact-credits-label {
        color: rgba(0, 0, 50, 1);
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 4px !important;
        margin-left: 0 !important;
    }

    & > div:nth-child(1) {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        .spotdif-outlined-field {
            width: 100% !important;
            font-weight: 500;
            color: rgba(0, 0, 50, 1);
            margin-bottom: 15px;
        }
        .spotdif-outlined-field--input {
            min-height: 46px;
            border-radius: 31px !important;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: rgba(0, 0, 0, 1);
        }
        .spotdif-outlined-field--input ::placeholder {
            color: rgba(0, 0, 0, 1);
            font-weight: 400;
            font-size: 16px;
        }
    }

    & > div:nth-child(2) {
        width: 100%;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: end;
        // gap: 10px;
        > div:nth-child(1) {
            border: 2px solid $COLOR_BG;
            // min-width: 420px;
            // max-width: 420px;
            min-height: 326px;
            width: 95%;
            border-radius: 12px;
            padding: 21px;
            margin-right: 0 !important;
            margin: auto;
            margin-bottom: 16px;
            h6 {
                text-align: center;
                font-weight: 600;
                font-size: 22px;
                line-height: 20px;
            }
            ul {
                list-style: none;
                margin-bottom: 0 !important;
                margin-top: 28px !important;
                padding-left: 0 !important;
                li {
                    display: flex;
                    gap: 25px;
                    justify-content: space-between;
                    & > span:nth-child(1) {
                        width: 70%;
                        color: rgba(103, 116, 137, 1);
                        line-height: 30px;
                        font-weight: 500;
                        font-size: 16px;
                    }
                    & > span:nth-child(2) {
                        color: rgba(17, 23, 41, 1);
                        font-weight: 500;
                        font-size: 16px;
                    }

                    // & > span:nth-child(2){

                    // }
                }
            }
            .bottom_list_ul {
                border-top: 1px solid #e3e8ef;
                margin-top: 16px !important;
                padding-top: 16px !important;
                padding-left: 0 !important;
                // & > li:last-child {
                //     & > span:nth-child(1) {
                //         color: #ff4e02;
                //     }
                //     margin-top: 10px;
                //     border-top: 1px solid #999;
                //     padding-top: 10px;
                // }
            }
        }

        & > div:nth-child(2) .button {
            background-color: $COLOR_BG;
            color: #fff;
            opacity: 1;

            margin: auto;
        }
    }
}

@media screen and (max-width: 750px) {
    .buy-contact-credits{
        flex-direction: column;
        & > div:nth-child(2){
            > div:nth-child(1) {
                margin-left: 0px;
            }
        }
    }
}





// v3- contact credit css

.available-contact {
    display: flex;
    gap: 15px;

    > div {
        padding: 5px 30px;
        display: flex;
        border-radius: 10px;
        gap: 10px;
        align-items: center;
        justify-content: center;

        span:last-child {
            font-size: 14px;
            font-weight: 600;
        }

        &:last-child {
            border: none;
        }
    }

    > div:nth-child(1) {
        background-color: rgb(225, 255, 220);
        color: rgb(7, 161, 4);
        width: 127px;
        min-height: 40px;
        font-size: 14px;
    }

    > div:nth-child(2) {
        background-color: rgba(255, 245, 220, 1);
        color: rgba(226, 177, 2, 1);
        width: 127px;
        min-height: 40px;
        font-size: 14px;
    }

    > div:nth-child(3) {
        background-color: rgba(255, 225, 220, 1);
        color: rgba(161, 4, 4, 1);
        width: 127px;
        min-height: 40px;
        font-size: 14px;
    }
}

.confirm_order_btn {
    width: 95%;
    margin-right: 0 !important;
    margin: auto;
}
.bottom_list_ul {
    li {
        .total_amount {
            font-weight: 700 !important;
            font-size: 20px !important;
            line-height: 165%;
            letter-spacing: 0.005em;
            color: #050505 !important;
        }
    }
}

.btn-disabled{
    opacity: 0.5 !important;
}