
          $font-path: "C:\Users\pc\Documents\new\spodiff-github\spotdif\src\assets\fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.text-orange {
    color: $COLOR_PRIMARY;

    svg {
        margin-right: 7px;
        vertical-align: middle;
    }
}

.tab-user-content {
    h6 {
        // color: $COLOR_BG;
        color: $DARK_BLUE;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        list-style: none;
        margin-bottom: 20px;
    }

    .secondary-user-content-card {
        padding: 24px 20px;
    }
    &-card {
        background: #fff 0 0 no-repeat padding-box;
        border-radius: 10px;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.102);
        opacity: 1;
        padding: 35px 25px;
        min-height: 265px;

        .criteria-form {
            display: flex;
            justify-content: space-between;
            gap: 10px;

            & > div {
                width: 95%;
                display: flex;
                gap: 20px;
                @media screen and (max-width: 576px) {
                    gap: 6px;
                }
            }
            .spotdif-field {
                & > label {
                    color: $COLOR_BG;
                    margin-bottom: 12px;
                }
            }

            .no-div {
                background: #f2f2f9;
                width: 48px;
                height: 45px;
                font-size: 16px;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                top: 38px;
                max-width: 44px;
                margin-right: 10px;
                @media screen and (max-width: 576px) {
                    margin-right: 2px;
                }
            }
            .delete-icon {
                & > span {
                    position: relative;
                    top: 44px;
                    svg {
                        width: 20px;
                    }
                }
            }
        }

        @media screen and (max-width: 1366px) {
            padding: 35px 25px;
        }

        @media screen and (max-width: 576px) {
            padding: 35px 20px;
        }

        .form-middle {
            display: flex;
            box-sizing: border-box;
            flex-wrap: wrap;
            gap: 0;

            .spotdif-field {
                border-radius: 12px;
                margin-bottom: 18px;
                background: transparent !important;
                padding: 0 10px;
                @media screen and (max-width: 768px) {
                    padding-left: 0;
                    padding-right: 0;
                }

                &.dropdown-input {
                    margin-bottom: 20px;
                    .spotdif-field {
                        width: 100% !important;
                        margin-bottom: 0;
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }
    }
}

.field-with-delete {
    position: relative;
    .dropdown-input {
        width: 100% !important;
    }
    .spotdif-field {
        width: 100% !important;
        padding-right: 60px !important;
    }
    &-icon {
        position: absolute;
        top: 31px;
        right: 10px;
        background: #e4e7eb;
        height: 42px;
        width: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        line-height: 20px;
        & > span {
            font-size: 17px;
        }
    }
}
.form-footer {
    flex-wrap: wrap;
    width: 100%;

    @media screen and (max-width: 576px) {
        width: 100%;

        button {
            width: 100%;
        }
    }

    &.form-footer--action-group {
        margin-top: 1rem;
        margin-bottom: 1rem;
        justify-content: start;
    }

    .button {
        &:first-child {
            margin-right: 10px;
        }

        @media screen and (max-width: 700px) {
            &:first-child {
                margin-right: 0;
                margin-bottom: 10px;
            }

            width: 100%;
        }
    }
}

.Mui-focused {
    fieldset {
        border-color: $COLOR_PRIMARY !important;
    }
}

label.Mui-focused {
    color: $COLOR_PRIMARY !important;
}

.teamMemberWrapper {
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.teamMemberHeading {
    color: $COLOR_BG;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 20px;
}

.teamMemberCard {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #0000001a;
    border-radius: 10px;
    opacity: 1;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;

    .teamMemberCardContent {
        justify-content: space-between;
        width: calc(100% - 200px);
        p {
            min-width: 260px;
            text-align: left;
        }
    }
}

@media (max-width: 900px) {
    .teamMemberCard {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.teamMemberCardIcons {
    display: flex;
    gap: 16px;
    cursor: pointer;
}

.teamMemberCardIcons p {
    text-align: right;
    font: normal normal normal 15px/19px Euclid Circular A;
    letter-spacing: 0px;
    color: #ff5252;
    opacity: 1;
}

.addTeamMembersBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
    font-size: 16px;
    line-height: 18px;
}

.addTeamBtnWrapper {
    display: flex;
    justify-content: center;
}

.button-group .addTeamMembersBtn {
    background-color: #f4f4fa;
}

.addTeamMemberIcon {
    padding-left: 0.4rem;
}

.teamMemberCardContent {
    display: none;
    // gap: 2.5rem;
}

.teamMemberCardContentMobile {
    display: flex;
}

@media (min-width: 900px) {
    .teamMemberCardContentMobile {
        display: none;
    }

    .teamMemberCardContent {
        display: flex;
        gap: 2.5rem;
    }
}

.teamMemberCardContent p {
    min-width: 5rem;
    text-align: left;
    font-weight: 500;
    color: $COLOR_BG;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
}
.teamMemberCardContent p.team-member-email {
    min-width: 5rem;
    text-align: left;
    font-weight: 500;
    color: $COLOR_BG;
    white-space: wrap;
    width: 260px;
}

.teamMemberCardContentMobile p {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: $COLOR_BG;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
    @media (min-width: 320px) and (max-width: 425px) {
        width: 60px;
    }
    @media (min-width: 425px) and (max-width: 600px) {
        width: 100px;
    }
    @media (max-width: 600px) and (min-width: 768px) {
        width: 100px !important;
    }
}

@media (max-width: 768px) {
    .teamMemberCardContentMobile > p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 295px;
    }
}

@media (max-width: 645px) {
    .teamMemberCardIcons {
        align-items: flex-start;
    }

    .teamMemberCard {
        // flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        padding: 10px;
    }
}

@media (max-width: 584px) {
    .teamMemberCard {
        flex-direction: row;
        align-items: center;
        // justify-content: center;
        gap: 0;
    }

    .teamMemberCardContent {
        // flex-direction: column;
    }

    .teamMemberCardIcons p {
        height: 0;
        opacity: 0;
    }

    .teamMemberCardIcons {
        gap: 0;
    }
}

@media (max-width: 400px) {
    .teamMemberCardContentMobile > p {
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100px;
        overflow: hidden;
    }

    .teamMemberCardIcons img {
        order: -1;
    }
}

.resetPassword {
    margin-left: 2rem;
    margin-top: 0.5rem;
    color: #3636eb;
}

// version 3 scss
.user-profile-block {
    display: flex;
    gap: 20px;
    & > div {
        width: 50%;
    }

    & > form {
        width: 50%;
    }
}

.secondary-user-content-card {
    table {
        tr {
            th,
            td {
                // padding-top: 10px;
                // padding-bottom: 10px;
                padding: 10px 20px;
            }
        }
    }

    .delete-auth-icon {
        color: $DANGER_COLOR;
        font-size: 22px !important;
    }

    .replay-auth-icon {
        font-size: 22px !important;
    }
}

.user-profile-seprator {
    border-color: #00003233;
    margin: 30px 0;
}

.team-members {
    display: flex;
    flex-direction: column;
    // gap: 30px;

    .team-members-header-block {
        display: flex;
        justify-content: space-between;

        .addTeamMembersBtn {
            .add-plus-icon {
                margin-right: 10px;
                padding: 0px 7px;
                background: #fff;
                color: $COLOR_BG;
                border-radius: 6px;
                font-size: 18px;
            }

            &:hover {
                .add-plus-icon {
                    background: $COLOR_BG;
                    color: #fff;
                }
            }
        }
    }
    .team-table-heading {
        .teamMemberCardContent {
            p {
                color: #565656;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
    .teamMemberCard {
        .teamMemberCardContent {
            p {
                color: #2c2c2c;
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
}
