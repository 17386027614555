
          $font-path: "C:\Users\pc\Documents\new\spodiff-github\spotdif\src\assets\fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.table-v2 {
    .table-card-block {
        display: flex;
        gap: 32px;
        margin-bottom: 20px;
    }

    .table-v2-block {
        padding: 10px;
        background-color: #fff;
        border-radius: 8px;
        // height: 70vh;

        & > div {
            height: 70vh;

            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .table-list-scroller {
            &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #afb8cc;
                // outline: 1px solid slategrey;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 2px #dfe2e9;
            }
        }

        // overflow-y: auto;

        // &::-webkit-scrollbar {
        //     display: none;
        // }

        table {
            border-collapse: unset;
            margin-top: -10px;

            //     height: 70vh;
            // overflow-y: auto;

            thead {
                th {
                    background: #eaeaf9;
                    padding: 12px 0px;
                    font-size: 16px;
                    font-weight: 500;
                    // text-align: center;

                    &:first-child {
                        border-top-left-radius: 8px;
                    }

                    &:last-child {
                        border-top-right-radius: 8px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        text-align: left;
                        font-size: 16px;
                        font-weight: 600;

                        .status_icon {
                            img {
                                width: 21px;
                                height: 21px;
                                border-radius: 0;
                            }

                            .report-rejected {
                                width: 32px;
                                height: 32px;
                                margin-left: -8px;
                            }
                        }

                        &:first-child {
                            border-top-left-radius: 8px;
                            border-bottom-left-radius: 8px;
                        }

                        &:last-child {
                            border-top-right-radius: 8px;
                            border-bottom-right-radius: 8px;
                        }
                    }

                    &:nth-of-type(even) td {
                        background: #f6f6f6;
                        padding: 20px 0;
                    }

                    &:nth-of-type(odd) td {
                        padding: 10px 0;
                    }

                    // tr td{

                    // }
                }
            }
        }
    }
}

.common-table-btn {
    padding: 6px 10px;
    border: 1px solid #142f73;
    border-radius: 20px;
    font-weight: 600;
    color: #142f73;

    &.table-btn-actve,
    &:hover,
    &.active-expand {
        color: #fff;
        background: #142f73;
    }
}

.more-info-table-btn,
.note-table-btn,
.report-table-btn {
    padding: 6px 10px;
    border-radius: 20px;
    font-weight: 600;
    background-color: #fff;
    cursor: pointer;

    &.table-btn-actve,
    &:hover,
    &.active-expand {
        color: #fff;
    }
}

.more-info-table-btn {
    border: 1px solid #142f73;
    color: #142f73;

    &.table-btn-actve,
    &:hover,
    &.active-expand {
        background: #142f73;
    }
}

.note-table-btn {
    border: 1px solid #7a798f;
    color: #7a798f;

    &.table-btn-actve,
    &:hover,
    &.active-expand {
        background: #7a798f;
    }
}

.report-table-btn {
    border: 1px solid #ff5252;
    color: #ff5252;

    &.table-btn-actve,
    &:hover,
    &.active-expand {
        background: #ff5252;
    }
}

/* Using !important because the css is copied by someone in another tables which are not allowing to override css without !important*/

.gray-table-btn {
    color: $COLOR_LABEL !important;
    border: 1px solid $COLOR_LABEL !important;

    &.table-btn-actve,
    &:hover,
    &.active-expand {
        color: #fff !important;
        background: $COLOR_LABEL !important;
    }
}
.danger-table-btn {
    color: $DANGER_COLOR !important;
    border: 1px solid $DANGER_COLOR !important;

    &.table-btn-actve,
    &:hover,
    &.active-expand {
        color: #fff !important;
        background: $DANGER_COLOR !important;
    }
}

.primary-table-btn {
    color: $COLOR_PRIMARY !important;
    border: 1px solid $COLOR_PRIMARY !important;

    &.table-btn-actve,
    &:hover,
    &.active-expand {
        color: #fff !important;
        background: $COLOR_PRIMARY !important;
    }
}

.expanded-row {
    width: 100%;
    background: #fff; // temporary
    display: table-row;
    max-height: 482px;

    .expanded-row-block {
        display: flex;
        padding: 5px 0px;
        gap: 15px;

        .expanded-heading {
            font-size: 28px;
            text-align: left;
            color: #000032;
            font-weight: 600;
        }

        .info-items {
            padding-bottom: 20px;

            .sub-heading {
                color: #79798f;
                font-size: 12px;
                font-weight: 400;
            }

            .sub-value {
                color: #000030;
                font-size: 14px;
                font-weight: 400;
                max-width: 200px;
                // overflow-x: auto;
            }
        }

        .badge {
            border-radius: 18px;
        }

        .block-left {
            max-height: 450px;
            overflow: auto;
            width: 60%;
            background: #f8f8f8;
            padding: 30px 60px;
            border-radius: 8px;

            .expanded-heading {
                margin-bottom: 22px;
            }

            .info {
                display: flex;

                .info-right,
                .info-left {
                    width: 50%;
                }

                .info-items {
                    .sub-value {
                        padding-bottom: 5px;
                    }
                }
            }
        }

        .block-right {
            width: 40%;
            padding: 30px 30px;
            border: 1px solid $COLOR_BORDER_V2;
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: fit-content;

            .block-right-header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 48px;

                .expanded-heading {
                    color: $COLOR_HEADING_V2;
                }

                span {
                    padding: 10px;
                    border-radius: 100%;
                    background: $COLOR_HEADING_V2;
                    color: #fff;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }

            .info-items {
                display: flex;
                justify-content: space-between;

                .sub-heading {
                    font-size: 14px;
                }

                .sub-value {
                    font-size: 16px;
                    color: #79798f;
                }
            }

            .action-box {
                margin-top: 20px;
                display: flex;
                gap: 20px;

                &.action-box-long {
                    @media screen and (max-width: 1580px) {
                        flex-wrap: wrap;
                        gap: 10px !important;
                        margin-top: 10px !important;
                    }
                }

                @media screen and (max-width: 1366px) {
                    flex-wrap: wrap;
                    gap: 10px !important;
                    margin-top: 10px !important;
                }
            }
        }

        .custom-block-right-padding {
            padding: 10px 30px !important;
        }
    }
}

.badge-v2 {
    font-size: 14px;
    text-align: center;
    padding: 5px 23px;
    border-radius: 18px;
    text-transform: capitalize;
}

.Report-Accepted-v2 {
    background: #54acf91a;
    color: #54acf9;
}

.Valid-v2 {
    background: #cdf4e3;
    color: #1de993;
}

.Report-Rejected-v2 {
    background: hsl(0, 50%, 77%);
    color: $COLOR_HEADING_V2;
}

.pause-v2 {
    background: #f1e6cf;
    color: #ffa800;
}

.Reported-v2 {
    background: #ecedd2;
    color: #d8dc21;
}

.Archived-v2 {
    background: #e9e2e2;
    color: gray;
}

.archived-leads {
    opacity: 0.4;
}

.leads-table-v2 .table_export_leads .export-csv {
    background-color: $COLOR_BG;
    border: 1px solid COLOR_BG;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    text-align: center;
    font-size: 0.8rem;
    padding: 0.6rem 15px !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    &:hover {
        color: $COLOR_BG;
        background: $COLOR_WHITE;
    }
}

.leads-table-v2 .table_header .table_header-left .table_header-grid .table_header-col {
    padding-right: 15px !important;
}

.leads-header-align {
    align-items: stretch !important;
}

.table-v2-block.table-v2-responsive {
    display: none;
}

// RESPOSNIVE
@media screen and (max-width: 768px) {
    .client-leads-v2 {
        .table-v2-block,
        .pagination {
            display: none;
        }
    }

    .table-v2 {
        .table-v2-block.table-v2-responsive {
            display: block;
            border: 4px solid #fff !important;

            .expanded-row {
                display: table;
                height: auto;

                .expanded-row-block {
                    padding-left: 0;
                    padding-right: 0;
                    max-height: none;

                    .block-left {
                        max-height: none;
                    }
                }

                .table-v2-responsive-more-info {
                    width: 100%;
                }
            }

            .table-v2-responsive-row {
                margin-top: 0;
                border-spacing: 0px 1px;

                tr {
                    td {
                        text-align: center;

                        span.status_icon {
                            justify-content: center;
                        }

                        img {
                            width: 21px;
                            height: 21px;
                        }
                    }

                    td:first-child {
                        background-color: #eaeaf9;
                    }
                }
            }

            .table-v2-responsive-buttons {
                height: auto;
                padding: 10px 0;
                display: flex;
                justify-content: space-between;

                button {
                    width: 32%;
                    font-size: 14px;
                    font-weight: 500;
                }
            }

            .leads-more-details-btn {
                button {
                    width: 48%;
                }
            }

            .table-v2-responsive-more-info {
                height: auto;
                margin: 10px 0;

                .block-left {
                    width: 100% !important;
                    background: #f8f8fb;
                    padding: 20px;
                    border-radius: 20px;

                    h2.expanded-heading {
                        font-size: 20px;
                        color: #000032;
                        font-weight: 500;
                        margin-bottom: 14px;
                    }

                    .info {
                        display: flex;

                        .info-right,
                        .info-left {
                            width: 50%;

                            .info-items {
                                padding-bottom: 20px;

                                .sub-heading {
                                    color: #79798f;
                                    font-size: 12px;
                                    font-weight: 400;
                                }

                                .sub-value {
                                    color: #000030;
                                    font-size: 14px;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
            }

            .table-v2-responsive-add-note {
                height: auto;
                margin: 10px 0;
                width: 100%;

                .block-middle {
                    width: 100%;
                }

                .block-left,
                .block-middle {
                    width: 100%;
                    background: #f8f8fb;
                    padding: 20px;
                    border-radius: 20px;

                    h2.expanded-heading {
                        font-size: 20px;
                        color: $COLOR_PRIMARY;
                        font-weight: 500;
                        margin-bottom: 14px;
                    }

                    .action-block {
                        .spotdif-field {
                            width: 100% !important;
                        }

                        textarea {
                            border: 1px solid #e5e5ec;
                            border-radius: 8px;
                            padding: 10px;
                        }

                        input[type='button'] {
                            margin-top: 15px;
                        }

                        p {
                            color: #79798f;
                            font-size: 12px;
                            font-weight: 400;
                            margin-top: 12px;
                        }
                    }
                }
            }

            .table-v2-responsive-report-lead {
                height: auto;
                margin: 10px 0;
                width: 100%;

                .block-right {
                    width: 100% !important;
                }

                .block-left,
                .block-right {
                    width: 100%;
                    background: #f8f8fb;
                    padding: 20px;
                    border-radius: 20px;

                    .info-items {
                        display: block !important;
                        padding-bottom: 15px;

                        .sub-heading {
                            font-size: 12px;
                            font-weight: 400;
                            color: #79798f;
                        }
                    }

                    .badge {
                        border-radius: 18px;
                    }

                    .report-reason p {
                        color: #79798f;
                        font-size: 12px;
                        font-weight: 400;
                        margin-top: 12px;
                    }

                    .action-box {
                        flex-wrap: wrap;
                        gap: 10px !important;
                        margin-top: 10px !important;
                    }

                    .block-right-header {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 5px;

                        span {
                            padding: 10px;
                            border-radius: 100%;
                            background: #ff5252;
                            color: #fff;
                            width: 30px;
                            height: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                        }
                    }

                    h2.expanded-heading {
                        font-size: 20px;
                        color: #ff5252;
                        font-weight: 500;
                        margin-bottom: 14px;
                    }
                }
            }
        }
    }
}

.show-button {
    display: block;
}

.text-align-center {
    text-align: center !important;
}

@media screen and (max-width: 500px) {
    .table_export_leads.show-button {
        display: block;
    }
}

.show-more-info-popup {
    background: white;
    border-radius: 8px;
    height: auto;
    padding: 20px;
}
