
          $font-path: "C:\Users\pc\Documents\new\spodiff-github\spotdif\src\assets\fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.m-2 {
    margin: 2rem;
}

.font-weight-light {
    font-weight: 300;
}

.p-01 {
    padding-top: 0.1rem;
}

.m-rl {
    margin: 0rem 2rem;
}

.m-tb {
    margin: 2rem 0rem;
}

.justify-content-center {
    justify-content: center;
}

.icon-size {
    font-size: 1.4rem;
}

.confirm-badge-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px 0px;
    .confirm-badge-input-wrapper {
        // width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }
    .confirm-badge-input-save {
        margin-top: 20px;
        width: 30%;
    }
    .confirm-badge-input {
        width: 100%;
    }
    .spotdif-field {
        width: 100% !important;
    }
}
.confirm-badge-text-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 20px 0px;
}
.confirm-checkbox-wrapper {
    background-color: $COLOR_BG_LIGHT;
    display: flex;
    align-items: center;
    padding: 3px 23px 3px 6px;
    border-radius: 50px;
    margin-top: 10px;

    .confirm-checkbox-text {
        font-weight: 600;
        font-size: 16px;
    }
    .confirm-checkbox-text-checked {
        color: $COLOR_BLUE-SHADE_LIGHT;
    }

    .MuiCheckbox-root.checkbox-border svg {
        border-radius: 50px;
        background: #fff;
        border: 1px solid #fff;
    }

    .Mui-checked svg {
        background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 -1 22 22%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272.5%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e"),
            linear-gradient(195deg, #4CAF50, #4CAF50) !important;
        border-color: #4CAF50 !important;
    }
}

.confirm-checkbox-wrapper-checked {
    background-color: $COLOR_PRIMARY;
}

.confirm-badge-text-wrapper span {
    font-size: 18px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .confirm-badge-wrapper {
        margin: 100px 2px;
        .confirm-badge-heading {
            font-size: 1.5rem;
        }
        .confirm-badge-input-wrapper {
            // width: 80%;
        }
    }
    .confirm-badge-text {
        font-size: 1rem;
    }
    .confirm-checkbox-wrapper {
        padding: 2px 15px 2px 8px;
    }
    .confirm-checkbox-wrapper .confirm-checkbox-text {
        font-size: 1rem;
    }
}
