
          $font-path: "C:\Users\pc\Documents\new\spodiff-github\spotdif\src\assets\fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
@import 'styles/global.scss';

.user-details-wrapper {
    display: flex;
    gap: 20px;
}

.header-stepper {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    display: grid;
    grid-template-columns: 6fr 2fr;
    margin-bottom: 2rem;

    @media screen and (max-width: 991px) {
        grid-template-columns: 1fr;
    }
}

.onboarding-steps {
    grid-row-gap: 0;
    background: rgba(0, 0, 0, 0.102) 0 0 no-repeat padding-box;
    border-radius: 31px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    padding: 0;
    -webkit-user-select: none;
    user-select: none;
    width: 100%;

    &.onboarding-steps--25 {
        .step:nth-child(1) {
            background: $COLOR_GREEN-SHADE_1;
            color: #fff;

            .step__title {
                font-weight: 600;
            }

            .step__number {
                font-weight: 600;
            }

            .check-done {
                display: flex;
                width: 20px;
                height: 20px;
                background: #fff;
                border-radius: 50%;
                color: $COLOR_GREEN-SHADE_1;
                font-size: 12px !important;
                justify-content: center;
                align-items: center;
                margin-left: auto;
            }
        }
    }

    &.onboarding-steps--50 {
        .step:nth-child(1),
        .step:nth-child(2) {
            background: $COLOR_GREEN-SHADE_1;
            color: #fff;

            .step__title {
                font-weight: 600;
            }

            .step__number {
                font-weight: 600;
            }

            .check-done {
                display: flex;
                width: 20px;
                height: 20px;
                background: #fff;
                border-radius: 50%;
                color: $COLOR_GREEN-SHADE_1;
                font-size: 12px !important;
                justify-content: center;
                align-items: center;
                margin-left: auto;
            }
        }

        .step:nth-child(1) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            justify-content: center;

            .check-done {
                display: none;
            }
        }

        .step:nth-child(2) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    &.onboarding-steps--75 {
        .step:nth-child(1),
        .step:nth-child(2),
        .step:nth-child(3) {
            background: $COLOR_GREEN-SHADE_1;
            color: #fff;

            .step__title {
                font-weight: 600;
            }

            .step__number {
                font-weight: 600;
            }

            .check-done {
                display: flex;
                width: 20px;
                height: 20px;
                background: #fff;
                border-radius: 50%;
                color: $COLOR_GREEN-SHADE_1;
                font-size: 12px !important;
                justify-content: center;
                align-items: center;
                margin-left: auto;
            }
        }

        .step:nth-child(1) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            justify-content: center;

            .check-done {
                display: none;
            }
        }

        .step:nth-child(2) {
            border-radius: 0;
            justify-content: center;

            .check-done {
                display: none;
            }
        }

        .step:nth-child(3) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .step {
        align-items: center;
        border-radius: 30px;
        display: flex;
        height: 100%;
        justify-content: flex-start;
        padding: 8px 30px;
        color: $GRAY-1;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        opacity: 1;
        line-height: 20px;

        @media screen and (max-width: 1366px) {
            font-size: 16px;
            line-height: 18px;
        }

        @media screen and (max-width: 700px) {
            padding: 8px 12px 8px 24px;
        }

        @media screen and (max-width: 576px) {
            padding-left: 12px;
        }

        &.step--active {
            color: $COLOR_BG;

            .step__title {
                font-weight: 600;
            }

            .step__number {
                font-weight: 600;
            }
        }

        &.step--completed {
            background: $COLOR_GREEN-SHADE_1;
            color: #fff;

            .step__title {
                font-weight: 600;
            }

            .step__number {
                font-weight: 600;
            }

            .check-done {
                display: flex;
                width: 20px;
                height: 20px;
                background: #fff;
                border-radius: 50%;
                color: $COLOR_GREEN-SHADE_1;
                font-size: 12px !important;
                justify-content: center;
                align-items: center;
                margin-left: auto;

                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
        }

        &.leftBorderStyles {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }

        &.rightBorderStyles {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            justify-content: center;

            .check-done {
                display: none;
            }
        }

        .step__number {
            font-weight: 500;
        }

        .step__title {
            margin-left: 10px;
            font-weight: 500;

            @media screen and (max-width: 700px) {
                display: none;
            }
        }

        .check-done {
            display: none;
        }
    }
}

.onboarding-actions {
    margin-left: auto;

    @media screen and (max-width: 991px) {
        // display: none !important;
        margin-left: 0;
    }

    button {
        color: #9fa1b3;
        cursor: pointer;
        font-size: 18px;
        font-weight: 500;
        -webkit-user-select: none;
        user-select: none;
        border: 1px solid $COLOR_PRIMARY;
        text-transform: capitalize;

        @media screen and (max-width: 1366px) {
            font-size: 16px;
            line-height: 18px;
        }

        @media screen and (max-width: 991px) {
            &:last-child {
                margin-left: auto;
            }
        }

        &.prev-button {
            border-radius: 30px;
            border: 1px solid #f0f2f5;

            .material-icons-round {
                margin-right: 5px;
            }
        }

        &.next-button {
            background-color: $COLOR_PRIMARY;
            border-radius: 30px;
            color: #fff;

            &:focus,
            &:hover {
                color: $COLOR_PRIMARY;
                background: #fff;
                border: 1px solid $COLOR_PRIMARY;
            }

            .material-icons-round {
                margin-left: 5px;
            }
        }
    }

    &.onboarding-action-footer {
        display: flex;
        margin-left: 0;
        margin-top: 20px;
        justify-content: flex-end;

        button:last-child {
            // margin-left: auto;
        }

        @media screen and (max-width: 991px) {
            // display: flex !important;
        }
    }
}

.form-actions-bottom {
    button.next-button {
        margin-left: auto;
    }
}

.layout-middle {
    .header-stepper {
        width: 100%;
        order: 1;
    }

    .form-actions-bottom {
        width: 100%;
        order: 3;
        margin-top: 15px;
        display: none !important;

        @media screen and (max-width: 991px) {
            display: flex !important;
        }
    }

    & > div {
        width: 100%;
        order: 2;
    }
}

.header-tabs--container {
    margin-bottom: 50px;

    &__right {
        display: flex;
        justify-content: flex-end;
        margin-left: auto;

        @media screen and (max-width: 1340px) {
            display: block;
        }

        @media screen and (max-width: 991px) {
            display: none;
        }

        .user-credits {
            display: inline-flex; // TODO: may be merge with other user credit styles
            margin-right: 20px;
            font-size: 16px;

            svg {
                margin-left: 5px;
                width: 23px;
                height: 23px;
            }
        }

        .button-solid {
            background-color: $COLOR_BLUE-SHADE_1;
            border-color: $COLOR_BLUE-SHADE_1;
            color: #fff;
            text-transform: capitalize;
            font-weight: 500;
            font-size: 16px;
            border-radius: 30px;

            &:hover,
            &:focus,
            &:visited {
                background-color: #fff;
                color: $COLOR_BLUE-SHADE_1;
            }

            svg {
                margin-left: 5px;
            }
        }
    }

    .account-tabs {
        display: flex;
        flex-wrap: wrap;
        height: 46px;

        @media screen and (max-width: 991px) {
            width: 100%;
        }

        @media screen and (max-width: 768px) {
            height: auto;
        }

        .tab {
            background-color: #f4f4fa; //TODO: Variable
            border-radius: 31px;
            color: $GRAY-1; //TODO: Variable
            display: flex;
            justify-content: center;
            max-width: 12rem;
            padding: 10px 67px;
            white-space: nowrap;
            font-size: 1rem;

            @media screen and (max-width: 1680px) {
                padding: 10px 30px;
            }

            @media screen and (max-width: 1480px) {
                padding: 10px 20px;
            }

            @media screen and (max-width: 1366px) {
                padding: 10px 15px;
            }

            @media screen and (max-width: 991px) {
                width: 20%;
                max-width: none;
            }

            @media screen and (max-width: 768px) {
                width: 100%;
                margin-bottom: 8px;
            }

            &.tab--active {
                background-color: $COLOR_BLUE-SHADE_1;
                color: $COLOR_WHITE;
            }
        }
    }
}

.team-table-heading {
    .teamMemberCardContent {
        justify-content: space-between;
        width: calc(100% - 200px);

        p {
            min-width: 260px;
            text-align: left;
        }
    }

    padding: 20px 40px;

    @media screen and (max-width: 900px) {
        display: none;
    }
}

.account-tabs-wrapper {
    .modalMainWrapper {
        justify-content: flex-start;
    }

    .payment-card {
        width: 50% !important;

        @media screen and (max-width: 991px) {
            width: 70% !important;
        }

        @media screen and (max-width: 768px) {
            width: 100% !important;
        }
    }
}
