
          $font-path: "C:\Users\pc\Documents\new\spodiff-github\spotdif\src\assets\fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.table-v2 {
    .table-card-block {
        display: flex;
        gap: 32px;
        margin-bottom: 20px;

        @media screen and (max-width: 991px) {
            gap: 20px !important;
        }

        @media screen and (max-width: 480px) {
            gap: 12px !important;
        }
    }

    .table-v2-block {
        padding: 10px;
        background-color: #fff;
        border-radius: 8px;
        // height: 70vh;

        & > div {
            height: 70vh;

            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        // overflow-y: auto;

        // &::-webkit-scrollbar {
        //     display: none;
        // }

        table {
            border-collapse: unset;
            margin-top: -10px;

            //     height: 70vh;
            // overflow-y: auto;

            thead {
                th {
                    background: #eaeaf9;
                    padding: 12px 0px;
                    font-size: 16px;
                    font-weight: 500;
                    // text-align: center;

                    &:first-child {
                        border-top-left-radius: 8px;
                    }

                    &:last-child {
                        border-top-right-radius: 8px;
                    }
                }
            }

            tbody {
                tr {
                    &.lead-record {
                        &.is-not-hydrated {
                            filter: grayscale(1);
                        }
                    }

                    td {
                        text-align: left;
                        font-size: 16px;
                        font-weight: 600;

                        &:first-child {
                            border-top-left-radius: 8px;
                            border-bottom-left-radius: 8px;
                        }

                        &:last-child {
                            border-top-right-radius: 8px;
                            border-bottom-right-radius: 8px;
                        }
                    }

                    &:nth-of-type(even) td {
                        background: #f6f6f6;
                        padding: 20px 0;
                    }

                    &:nth-of-type(odd) td {
                        padding: 10px 0;
                    }

                    // tr td{

                    // }
                }
            }
        }
    }
}

.common-table-btn {
    padding: 6px 10px;
    border: 1px solid #142f73;
    border-radius: 20px;
    font-weight: 600;
    color: #142f73;

    &.table-btn-actve,
    &:hover {
        color: #fff;
        background: #142f73;
    }
}

.expanded-row {
    width: 100%;
    background: #fff; // temporary
    display: table-row;

    .expanded-row-block {
        display: flex;
        padding: 5px 30px;
        gap: 15px;

        .expanded-heading {
            font-size: 28px;
            text-align: left;
            color: #000032;
            font-weight: 600;
        }

        .info-items {
            padding-bottom: 20px;

            .sub-heading {
                color: #79798f;
                font-size: 12px;
                font-weight: 400;
            }

            .sub-value {
                color: #000030;
                font-size: 14px;
                font-weight: 400;
            }
        }

        .badge {
            border-radius: 18px;
        }

        .block-left {
            width: 65%;
            background: #f8f8f8;
            padding: 30px 60px;
            border-radius: 8px;

            .expanded-heading {
                margin-bottom: 22px;
            }

            .info {
                display: flex;

                .info-right,
                .info-left {
                    width: 50%;
                }

                .info-items {
                    .sub-value {
                        padding-bottom: 5px;
                    }
                }
            }
        }

        .block-right {
            width: 35%;
            padding: 30px 30px;
            border: 1px solid #e5e5ec;
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            .block-right-header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 48px;

                .expanded-heading {
                    color: #ff5252;
                }

                span {
                    padding: 10px;
                    border-radius: 100%;
                    background: #ff5252;
                    color: #fff;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }

            .info-items {
                display: flex;
                justify-content: space-between;

                .sub-heading {
                    font-size: 14px;
                }

                .sub-value {
                    font-size: 16px;
                    color: #79798f;
                }
            }

            .action-box {
                display: flex;
                gap: 20px;
            }
        }
    }
}

.badge-v2 {
    font-size: 14px;
    text-align: center;
    padding: 5px 23px;
    border-radius: 18px;
    text-transform: capitalize;
}

.Report-Accepted-v2 {
    background: #54acf91a;
    color: #54acf9;
}

.Valid-v2 {
    background: #cdf4e3;
    color: #1de993;
}

.Report-Rejected-v2 {
    background: hsl(0, 50%, 77%);
    color: #ff5252;
}

.pause-v2 {
    background: #f1e6cf;
    color: #ffa800;
}

.Reported-v2 {
    background: #ecedd2;
    color: #d8dc21;
}

.Archived-v2 {
    background: #e9e2e2;
    color: gray;
}

.client-table-v2 .table_key-left.add-shadow {
    border: 1px solid #00000045 !important;
    box-shadow: unset !important;
    background: unset !important;

    @media screen and (max-width: 768px) {
        border: 0 !important;
        padding-right: 0;
        background: #fff !important;
    }
}

.client-table-v2 .form-select-small {
    background-color: unset !important;
}

.client-table-v2 .table_key-col {
    border-right: 1px SOLID #00000045 !important;
    padding: 0px 10px;
    height: 37px;

    @media screen and (max-width: 768px) {
        border-right: 0px !important;
    }

    .form-select-small {
        padding: 0;
        margin-bottom: 0;

        @media screen and (max-width: 768px) {
            border-bottom: 1px solid #000030;
            padding-bottom: 5px !important;
            border-radius: 0;
        }
    }
}

.client-table-v2 .table_key-left-grid .search_page {
    border-right: 1px solid #00000045 !important;

    @media screen and (max-width: 768px) {
        border-right: 0 !important;
    }
}

.client-table-v2 .table_key-col:last-child {
    border: 0 !important;
}

.client-table-v2 table tr th {
    padding: 18px 34px !important;
}

.client-table-v2 table {
    border-spacing: 0px !important;
    /* overflow-y: scroll; */
}

// .client-table-v2 table tbody tr td{
//     border-bottom: 15px solid #eef0f3 !important;
// }
.client-table-v2 table tbody tr:nth-of-type(even) td {
    background: #f6f6f6;
    // padding: 10px 32px !important;
}

td.details-row-expand.expanded-client-row {
    padding: 0 !important;
}

.client-table-v2 table tbody tr td .row-expand {
    background: #fff !important;
    padding: 0 !important;
}

.client-table-v2 table tbody tr:nth-of-type(odd) td {
    background: #fff;
    // padding: 10px 32px !important;
}

.client-table-v2 table tr td > img {
    width: 58px;
    height: 51px;
}

.client-table-v2 .expand-card-top.MuiBox-root.css-sxuyct h5 {
    color: #ff5252 !important;
}

.client-table-v2 .delete-icon svg {
    color: #ff5252 !important;
}

.client-tab-card-1 {
    opacity: unset !important;
}

.client-table-v2 {
    opacity: unset !important;
}

.client-table-v2 .css-acose8-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #ff5252 !important;
    border-color: #ff5252 !important;
    opacity: 0.5;
}

.client-table-v2 .css-11rehke-MuiSwitch-thumb {
    background-color: #ff5252 !important;
    border-color: #ff5252 !important;
}

.client-table-v2 .css-acose8-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
    border-color: #ff5252 !important;
}

.text_boxes {
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.text_boxes .table-v2 .table-card-block,
.text_boxes .table-card-block {
    margin-bottom: 0 !important;
}

.text_boxes .table-card-block {
    width: 772px !important;
}

.text_boxes .table-card-block.tableConfig {
    justify-content: end !important;
}

.table-v2 .table-v2-block,
.border_table_all {
    /* padding: 10px; */
    background-color: #fff !important;
    border-radius: 8px;
    border: 10px solid #fff !important;
    padding: unset !important;
}

.overlay .css-b03hz3-MuiButtonBase-root-MuiButton-root:disabled {
    background-color: #ffffff;
    color: #7b809a;
    margin-bottom: 20px !important;
}

.overlay .creditamount .expand-card-top {
    align-items: center !important;
}

// Just a fix due to copied code .
.expanded-client-row .creditamount .expand-card-top {
    align-items: flex-start !important;
}

@media (min-width: 320px) and (max-width: 767px) {
    .table-header .search_page .search-icon {
        left: 23px;
        top: 111px;
    }

    .text_boxes .table-card-block {
        width: 100% !important;
    }

    .table-header-card {
        width: calc(50% - 10px);
    }

    .text_boxes {
        flex-direction: column;
        width: 100% !important;
        align-items: baseline;
    }

    .table-card-block.tableConfig {
        flex-direction: initial;
    }

    .text_boxes {
        margin: auto;
    }

    .table-card-block.tableConfig {
        margin-top: 20px;
    }
}

@media screen and (max-width: 480px) {
    .table-header-card {
        width: calc(50% - 7px);
    }
}

// TAB FORM INPUT STYLES
.custom-cpl-input {
    input {
        text-align: right;
    }
}

.lead-cost-disable-input {
    input {
        text-align: right;

        &.Mui-disabled {
            opacity: 0;
        }
    }

    input + .input-adorement {
        opacity: 0.4;
    }
}

.previous-table-header {
    background-color: #eaeaf9;
    font-weight: 800;

    tr {
        th {
            span {
                font-weight: 600;
            }
        }
    }
}

.expanded-client-row {

    .expand-field-value,
    .expand-card-input,
    .autocomplete-field {
        & .spotdif-field {
            width: 100% !important;
        }
    }
}

.client-table-field {
    .spotdif-field {
        div {
            border-style: none !important;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        right: -16px !important;
    }

    .css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input {
        min-width: 100%;
        padding: 13.5px 0px 13.5px 8.5px !important;
    }
}

.client-table-v2 {
    .status-img {
        border-radius: 0;
        width: 20px;
        height: 20px;
    }
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.view-list-icon {
    width: 30px;
    height: 30px;
    color: #344767;
}

.send-test-lead-button {
    justify-content: space-between;
}

.table-card-block.tableConfig.table-card-block.justify-flex-end {
    justify-content: flex-end;
}

.fix-disabled-background div {
    width: 94%;
}

.text-field-icon svg.edit-content-icon {
    margin-right: 25px;
    cursor: pointer;
}

.button-disabled {
    cursor: not-allowed;
    opacity: 0.3;
}
