
          $font-path: "C:\Users\pc\Documents\new\spodiff-github\spotdif\src\assets\fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.brand-panel-wrapper {
    // background-color: #ffffff;
    border-radius: 12px;
    margin: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 768px) {
        margin: 10px;
        flex-wrap: wrap;
    }
    // height: 65vh;
}
.brand-panel-wrapper-white {
    background-color: #ffffff;
    padding: 50px 0;
    @media screen and (max-width: 1199px) {
        padding: 10px 0;
    }
    .brand-panel-wrapper-right {
        padding-right: 0;
    }
    .brand-panel-border {
        border-bottom: 1px solid #5fa2fb;
        width: 50%;
    }
}
.brand-panel-wrapper-blue {
    // background-color: #dce7f5;
    background-color: $COLOR_PRIMARY;
    padding: 70px 0;
    @media screen and (max-width: 991px) {
        padding: 30px 0;
    }
}
.brand-panel-wrapper-left {
    width: 50%;
    padding: 4rem 8rem;

    @media screen and (max-width: 1280px) {
        padding: 3rem;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
    }
    @media screen and (max-width: 580px) {
        padding: 2rem;
    }
    @media screen and (max-width: 480px) {
        padding: 1.5rem;
    }
}
.brand-panel-wrapper-right {
    width: 50%;
    padding-right: 100px;
    @media screen and (max-width: 768px) {
        width: 100%;
        padding-left: 40px;
        padding-bottom: 20px;
        padding-right: 40px;
    }

    img {
        display: block;
        margin-left: auto;
    }
}

.brand-panel-title {
    span.semibold {
        font-weight: 600;
        color: $COLOR_WHITE;
    }
}

.brand-panel-wrapper-white .brand-panel-title {
    width: 14ch;
    letter-spacing: 0px;
    color: $DARK_BLUE;
    opacity: 1;
    font-size: 46px;
    line-height: 66px;
    // border-bottom: 1px solid #5fa2fb;

    @media screen and (max-width: 1366px) {
        width: auto;
        line-height: 56px;
    }
    @media screen and (max-width: 580px) {
        font-size: 40px;
        line-height: 48px;
    }
    @media screen and (max-width: 580px) {
        font-size: 34px;
        line-height: 42px;
    }
}
.brand-panel-wrapper-white .brand-panel-title > p {
    width: 14ch;
    letter-spacing: 0px;
    color: $DARK_BLUE;
    opacity: 1;
    font-size: 46px;
    line-height: 66px;
    // border-bottom: 1px solid #5fa2fb;

    @media screen and (max-width: 1366px) {
        width: auto;
        line-height: 56px;
    }
    @media screen and (max-width: 580px) {
        font-size: 40px;
        line-height: 48px;
    }
    @media screen and (max-width: 580px) {
        font-size: 34px;
        line-height: 42px;
    }
}
.brand-panel-wrapper-blue .brand-panel-title {
    width: 22ch;
    letter-spacing: 0px;
    color: $DARK_BLUE;
    opacity: 1;
    font-size: 46px;
    line-height: 56px;
    @media screen and (max-width: 1480px) {
        width: auto;
        font-size: 42px;
        line-height: 54px; // list-style: none;52px
    }
    @media screen and (max-width: 768px) {
        width: auto;
        font-size: 34px;
        line-height: 42px; // list-style: none;52px
    }
}
.brand-panel-wrapper-blue .brand-panel-title > p {
    width: 14ch;
    letter-spacing: 0px;
    color: $DARK_BLUE;
    opacity: 1;
    font-size: 46px;
    line-height: 56px;
    @media screen and (max-width: 1480px) {
        width: auto;
        font-size: 42px;
        line-height: 54px; // list-style: none;52px
    }
    @media screen and (max-width: 768px) {
        width: auto;
        font-size: 34px;
        line-height: 42px; // list-style: none;52px
    }
}
.brand-panel-details-wrapper {
    margin: 20px 0px;
    .width-42ch {
        color: $NEW_TEXT_GREY;
    }
}
.brand-panel-details {
    // color: $NEW_TEXT_GREY;
    margin-bottom: 6px;
    line-height: 24px;
    font-size: 16px;
    margin-left: 6px;
    margin-top: 6px;
}
.discover-details {
    display: flex;
    align-items: center;
    // img {
    //     margin-top: 8px;
    //     margin-right: 8px;
    // }
}
.brand-panel-wrapper,
.replacement-content-wrapper {
    .spotdif-animated-arrow-btn {
        border-radius: 30px;
        padding: 10px 20px;
        text-transform: none;
        text-align: left;
        position: relative;
        transition: none;
        background-color: transparent;
        padding-right: 60px;
        &.animated-arrow-dark {
            &:hover {
                p {
                    color: #000032;
                }
            }
        }
        p {
            color: $COLOR_WHITE;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            margin-bottom: 0;
            position: relative;
            text-align: left;
            z-index: 10;
        }
        .spotdif-arrow {
            background-color: $COLOR_PRIMARY;
            width: 100%;
            height: 44px;
            position: absolute;
            right: 0;
            top: 0;
            transition: width 0.3s ease-in-out;
            z-index: 1;
            border-radius: 30px;
            text-align: right;
            color: #fff;
            svg {
                margin-top: 13px;
                margin-right: 14px;
            }
        }
        &:hover {
            transform: none;
            background: transparent;
            .spotdif-arrow {
                width: 44px;
            }
        }
    }
    .replacement-btn {
        margin-bottom: 40px;
    }
}

.width-42ch {
    width: 42ch;
}
@media screen and (max-width: 768px) {
    .width-42ch {
        width: auto;
    }
}
