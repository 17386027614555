@font-face {
    font-family: 'Euclid circular a';
    src: url('../assets/font/Euclid-Circular-A-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid circular a';
    src: url('../assets/font/Euclid-Circular-A-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid circular a';
    src: url('../assets/font/Euclid-Circular-A-Bold-Italic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid circular a';
    src: url('../assets/font/Euclid-Circular-A-SemiBold-Italic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid circular a';
    src: url('../assets/font/Euclid-Circular-A-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid circular a';
    src: url('../assets/font/Euclid-Circular-A-Medium-Italic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid circular a';
    src: url('../assets/font/Euclid-Circular-A-Light-Italic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid circular a';
    src: url('../assets/font/Euclid-Circular-A-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid circular a';
    src: url('../assets/font/Euclid-Circular-A-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid circular a';
    src: url('../assets/font/Euclid-Circular-A-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
