
          $font-path: "C:\Users\pc\Documents\new\spodiff-github\spotdif\src\assets\fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.signup-wrapper {
    padding: 7px 24px 16px;

    @media screen and (max-width: 991px) {
        width: 100% !important;
    }

    .spotdif-signup-welcometext {
        color: #fff;
        font-weight: 500;
        margin: 1rem 0 2rem;
    }

    .spotdif-signup-cards {
        .signupcard-body {
            padding: 1rem;
            text-align: left;

            @media screen and (max-width: 576px) {
                padding: 0;
            }
        }
    }

    .spotdif-condition-field {
        margin-right: 0;

        @media screen and (max-width: 576px) {
            margin-top: -10px;
        }

        .Mui-checked {
            color: $COLOR_PRIMARY;

            .MuiSvgIcon-root {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"),
                    linear-gradient(195deg, #ff4e02, #ff4e02);
                border-color: $COLOR_PRIMARY;
            }
        }

        a {
            background-image: linear-gradient(195deg, $COLOR_PRIMARY, $COLOR_PRIMARY);
            font-weight: 400;
        }
    }

    .login-footer {
        font-size: 16px;
        margin-bottom: 20px;
    }
}

.spotdif-field.spotdif-field-dark {
    label {
        margin-left: 8px;
    }
    input {
        color: #fff !important;
    }
}
