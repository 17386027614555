
          $font-path: "C:\Users\pc\Documents\new\spodiff-github\spotdif\src\assets\fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.spotdif-full-dashboard-layout {
    .toggle-sidebar-icon {
        padding: 0;
        background-color: transparent;
        width: 32px;
        min-width: 32px;
        display: none;

        &:hover {
            background: transparent;
        }

        @media screen and (max-width: 1199px) {
            display: flex;
        }

        & > img {
            width: 32px;
        }
    }
}

.layout-header {
    display: flex;
    background: $COLOR_BLUE-SHADE_LIGHT;
    padding-left: 3.5%;
    padding-right: 5%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    align-items: center;

    @media screen and (max-width: 1199px) {
        padding-left: 25px;
        padding-right: 25px;
    }

    @media screen and (max-width: 568px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media screen and (max-width: 320px) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .layout-main-heading {
        font-size: 2.5rem !important;
        font-weight: 700 !important;
        line-height: 1.2 !important;
        color: $COLOR_BG !important;
        margin-right: 1rem;
        @media screen and (max-width: 1199px) {
            margin-left: 15px;
            font-size: 2rem !important;
            line-height: 1 !important;
        }

        @media screen and (max-width: 568px) {
            font-size: 1.8rem !important;
            line-height: 1 !important;
        }

        @media screen and (max-width: 568px) {
            font-size: 1.5rem !important;
            line-height: 1 !important;
        }
    }
}

.application-banners {
    padding-left: 3.5%;
    padding-right: 3.5%;

    .banner-container {
        border-radius: 30px;
        padding: 12px 12px 12px 40px;
        margin-top: 2rem;

        @media screen and (max-width: 1199px) {
            padding-left: 30px;
        }

        @media screen and (max-width: 768px) {
            padding-left: 20px;
            border-radius: 20px;
            margin-top: 1rem;
        }

        & > .MuiBox-root {
            width: 100%;

            @media screen and (max-width: 576px) {
                flex-wrap: wrap;
            }
        }

        p {
            font-size: 16px;
            font-weight: 600;
            color: #fff;
            margin-right: 10px;

            @media screen and (max-width: 576px) {
                width: 100%;
                text-align: center;
                margin-right: 0;
                line-height: 22px;
            }
        }

        button,
        a {
            margin-left: auto;
            background-color: #f4f4fa;
            border-radius: 31px;
            color: $COLOR_PRIMARY;
            display: flex;
            justify-content: center;
            font-size: 16px;
            max-width: 12rem;
            padding: 10px 67px;
            white-space: nowrap;
            text-transform: capitalize;

            @media screen and (max-width: 576px) {
                width: 100%;
                margin: 10px auto 0;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .banner-add-credit-button {
        margin-top: 0px !important;
    }
}
