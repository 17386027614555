
          $font-path: "C:\Users\pc\Documents\new\spodiff-github\spotdif\src\assets\fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
body {
    background-color: #f4f4fa !important;
}
.signup-wrapper-new {
    display: flex;
    margin: 30px 20px 0;
    gap: 15px;
    height: calc(100vh - 146px);
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
    }
    @media screen and (max-width: 768px) {
        margin-left: 10px;
        margin-right: 10px;
        height: 100%;
        margin-top: 10px;
    }
}
.left-wrapper {
    background: $COLOR_PRIMARY;
    width: 50%;
    border-radius: 12px;
    // margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 30px 145px 30px 65px;
    @media screen and (max-width: 1480px) {
        padding-left: 50px;
    }
    @media screen and (max-width: 1280px) {
        padding: 50px 50px;
    }
    @media screen and (max-width: 1024px) {
        padding: 30px;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        padding-top: 180px;
    }
    @media screen and (max-width: 480px) {
        padding: 30px 25px;
        padding-top: 180px;
    }
}
.right-wrapper {
    background: $COLOR_BG;
    border-radius: 12px;
    width: 50%;
    padding: 30px 65px 30px 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .spotdif-signup-Button {
        margin-top: 20px;
    }
    .spotdif-login-wrapper form.spotdif-login-form {
        padding: 0;
        input,
        .spotdif-field {
            width: 100%;
        }
    }
    @media screen and (max-width: 1480px) {
        padding-left: 250px;
    }
    @media screen and (max-width: 1366px) {
        padding-left: 200px;
    }
    @media screen and (max-width: 1280px) {
        padding: 30px 50px;
    }
    @media screen and (max-width: 1024px) {
        padding: 20px 50px;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        padding: 40px;
    }
    @media screen and (max-width: 480px) {
        padding: 30px 25px;
    }
}
.title-wrapper {
    display: flex;
    letter-spacing: 0px;
    color: #142f73;
    opacity: 1;
    @media screen and (max-width: 480px) {
        font-size: 16px;
    }
}
.join-hands {
    margin-right: 10px;
}
.signup-heading > p {
    margin: 10px 0px;
    width: 15ch;
    letter-spacing: 0px;
    color: $COLOR_WHITE;
    font-size: 66px;
    line-height: 78px;
    font-weight: 500;
    @media screen and (max-width: 1750px) {
        width: 14ch;
    }
    @media screen and (max-width: 1480px) {
        font-size: 54px;
        line-height: 58px;
        padding-right: 30px;
        width: auto;
    }
    @media screen and (max-width: 1024px) {
        font-size: 42px;
        line-height: 50px;
        padding-right: 0;
    }
    @media screen and (max-width: 768px) {
        font-size: 44px;
        line-height: 52px;
    }
    @media screen and (max-width: 430px) {
        font-size: 30px;
        line-height: 35px;
    }
}
.signup-heading span,
code {
    color: $DARK_BLUE;
}
.sign-up-main {
    width: 720px;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    height: calc(100% - 115px);
    @media screen and (max-width: 1366px) {
        width: 580px;
    }
    @media screen and (max-width: 1280px) {
        display: none;
    }
}
.signup-para {
    width: 48ch;
    letter-spacing: 0px;
    color: #000032;
    font-size: 16px;
    @media screen and (max-width: 1480px) {
        padding-right: 60px;
    }
    @media screen and (max-width: 1366px) {
        padding-right: 0;
        width: auto;
    }
}
.signup-features {
    display: flex;
    width: 100%;
}
.signup-feature-text {
    color: #fff;
}
.two {
    margin-left: 13px;
}
.signup-heading-two {
    margin-bottom: 20px;
    color: #fff;
    font-weight: 500;
    width: 100%;
    @media screen and (max-width: 1480px) {
        font-size: 2.2rem;
    }
    @media screen and (max-width: 480px) {
        font-size: 1.9rem;
        font-weight: 500;
    }
}
.right-wrapper {
    .terms-and-conditions {
        margin-left: 3px;

        span,
        a {
            color: $NEW_TEXT_GREY;
        }

        & > label {
            display: flex;
        }

        @media screen and (max-width: 1024px) {
            margin-top: 15px;
        }
    }
}

.MuiButtonBase-root.MuiButton-root.spotdif-signup-Button.branding {
    background-color: $COLOR_PRIMARY;
    border-radius: 37px;
    padding: 10px 40px;
    color: $COLOR_WHITE;
    transition: width 1000ms ease-in-out;
    font-size: 18px;
    font-weight: 400;
    text-transform: none;

    &:hover {
        background-color: $COLOR_PRIMARY;
    }

    &:focus {
        box-shadow: none;
        background-color: $COLOR_PRIMARY;
    }
}
.MuiButtonBase-root.MuiButton-root.spotdif-signup-Button {
    background-color: $COLOR_PRIMARY;
    border-radius: 37px;
    padding: 10px 40px;
    color: $COLOR_WHITE;
    transition: width 1000ms ease-in-out;
    font-size: 18px;
    font-weight: 400;
    text-transform: none;

    &:hover {
        background-color: $COLOR_PRIMARY;
    }

    &:focus {
        box-shadow: none;
        background-color: $COLOR_PRIMARY;
    }
}

// ANIMATED BUTTON
.right-wrapper {
    form {
        width: 100%;
    }
    .spotdif-animated-arrow-btn {
        border-radius: 30px;
        padding: 10px 20px;
        text-transform: none;
        text-align: left;
        position: relative;
        transition: none;
        background-color: transparent;
        padding-right: 60px;
        p {
            color: $COLOR_WHITE;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            margin-bottom: 0;
            position: relative;
            text-align: left;
            z-index: 10;
        }
        .spotdif-arrow {
            background-color: $COLOR_PRIMARY;
            width: 100%;
            height: 44px;
            position: absolute;
            right: 0;
            top: 0;
            transition: width 0.3s ease-in-out;
            z-index: 1;
            border-radius: 30px;
            text-align: right;
            color: #fff;
            svg {
                margin-top: 13px;
                margin-right: 14px;
            }
        }
        &:hover {
            transform: none;
            background: transparent;
            .spotdif-arrow {
                width: 44px;
            }
        }
    }
}
.resposnive-form {
    .spotdif-textbox-flex {
        @media screen and (max-width: 1024px) {
            flex-wrap: wrap;
            gap: 15px;
            margin-bottom: 0;
            margin-top: 15px;
        }
        .spotdif-field {
            @media screen and (max-width: 1024px) {
                width: 100%;
            }
        }
    }
}
.signup-terms-conditions {
    font-size: 10px;
    position: absolute;
    bottom: 20px;
    color: $COLOR_WHITE;
    @media screen and (max-width: 768px) {
        display: none;
    }
}

/* Get Free lead Button */
button.sticky-get-free-leads {
    z-index: 9 !important;
}
.css-181t24u-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
    margin-right: -14px !important;
}

@media (min-width: 768px) and (max-width: 991px) {
    button.sticky-get-free-leads {
        padding: 9px 60px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .terms a,
    .privacy a {
        color: #fff;
        font-size: 13px;
    }
    button.sticky-get-free-leads {
        padding: 9px 60px;
    }
}
.input-adornment-select-mobile-prefix div {
    color: #fff;
}
