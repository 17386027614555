
          $font-path: "C:\Users\pc\Documents\new\spodiff-github\spotdif\src\assets\fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.stats-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    & > :nth-child(1) {
        .MuiAutocomplete-inputRoot {
            flex-wrap: nowrap;
        }
    }
    & > :nth-child(2) {
        display: flex;
        justify-content: end;

        position: relative;

        // top: 40px;
        .chart-date-picker-dropdown {
            position: absolute;
            top: 54px;

            p,
            button {
                font-size: 14px;
                margin-bottom: 0px;
            }

            h6 {
                font-size: 18px;
            }

            .MuiSelect-select.MuiSelect-standard.MuiInputBase-input {
                font-size: 16px;
            }
            hr {
                display: none;
            }
        }

        & > button {
            border: 0;
            background-color: #052473;
            color: #fff;
            font-size: 20px;
            border-radius: 7px;
            padding: 10px 12px 10px 16px;
            display: flex;
            align-items: center;
            letter-spacing: 0.1rem;
            // height: 25px;
            cursor: pointer;
            & > span {
                margin: 0 20px;
            }
        }
    }
}

.accout-manager-scrool-bar .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root {
    padding-right: 65px;
    width: 100%;
    overflow-x: auto;
    border: 0 !important;
    flex-wrap: wrap;
}
.accout-manager-scrool-bar .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12 {
    width: 70%;
}
.accout-manager-scrool-bar .stats-header > :nth-child(2) > button {
    font-size: 15px !important;
}
.accout-manager-scrool-bar .chart-wrapper .chart-date-filter .MuiGrid-item > button {
    min-width: 95px !important;
}
.accout-manager-scrool-bar {
    border: unset !important;
}
.accout-manager-scrool-bar .MuiOutlinedInput-root {
    padding-right: 65px;
    border-radius: 20px !important;
}
// .spotdif-outlined-field--input {
//     border: 1px solid rgba(0, 0, 0, 0.2) !important;
// }
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot
    span.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium {
    background: white;
    color: black;
}

.spotdif-field .MuiFormControl-root.auto-complete-input {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
}
.accout-manager-scrool-bar
    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiOutlinedInput-root::-webkit-scrollbar {
    width: 4px;
    height: 6px;
}

.accout-manager-scrool-bar
    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiOutlinedInput-root::-webkit-scrollbar-track {
    // background: #000032;
    border-radius: 10px;
}

.accout-manager-scrool-bar
    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiOutlinedInput-root::-webkit-scrollbar-thumb {
    background-color: #a7a7ba;
    border-radius: 10px;
    // border: 3px solid #000032;
}
.select-account-manager-grid {
    .dropdown-input-stats {
        .spotdif-field {
            .auto-complete-input {
                .MuiOutlinedInput-notchedOutline {
                    border: none !important;
                }
            }
        }
    }
}
