
          $font-path: "C:\Users\pc\Documents\new\spodiff-github\spotdif\src\assets\fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.buttonWrapper {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 20px;

    .toggleButton {
        background-color: #ff4e02;
        cursor: pointer;
        border: 0;
        background: $COLOR_PRIMARY;
        color: #fff;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
            background: #fff;

            svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.toggle-view-icon {
                width: 50px;
                height: 30px;
                color: #ff4e02;
            }
        }
    }

    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.toggle-view-icon {
        width: 50px;
        height: 30px;
        color: #fff;
    }
}

.popup-container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 25px;
    border-radius: 20px;
    min-width: 500px;

    .role-permission-field {
        div {
            width: 100% !important;
        }
    }
}

.permission-table-container {
    background-color: white;
    border-radius: 2%;
    margin: 4px;
    padding: 16px;
    min-width: 50vw;
    max-height: 500px;
    // overflow-y: clip;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    align-items: center;
    gap: 40px;

    .dropdownMenu {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 500px;
    }
}

.mr-4 {
    margin-right: 3rem;
}

.cursor-pointer {
    cursor: pointer;
}

.roleModalCloseWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .roleModalCloseBtn {
        opacity: 1;
        background-color: #fff;
        border-radius: 50%;
        height: 42px;
        width: 42px;
        min-width: 42px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 5px;

        svg {
            color: #000;
        }
    }
}

.gridWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px;
    padding: 20px;
}

.gridItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 20px;
    border-radius: 16px;
}

.girdChildItems {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.table-v2.client-leads-v2.update-permission-modal {
    overflow: hidden;
    max-height: 390px;
}

.update-permission-modal {
    .table-v2-block {
        .table-list-scroller {
            max-height: 390px;
        }
    }
}
