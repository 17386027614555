
          $font-path: "C:\Users\pc\Documents\new\spodiff-github\spotdif\src\assets\fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.flex-space-between {
    justify-content: space-between;
}

.selected {
    background-color: $COLOR_BG !important;
    color: white !important;
    border-color: $COLOR_BG !important;
}

.selected:hover {
    background-color: #fff !important;
    color: $COLOR_BG !important;
    border-color: $COLOR_BG !important;
}

.not-selected:hover {
    background-color: $COLOR_BG !important;
    color: #fff !important;
    border-color: $COLOR_BG !important;
}

.list-card-parent {
    gap: 30px;
    margin-top: 10px;
}

.card-container {
    // min-height: 1580px;
    padding-top: 3em;
    padding-bottom: 1.5em;
    padding-left: 2em;
    padding-right: 2em;
    max-width: 100%;

    .card-btn {
        background: $COLOR_BG   ;
        color: white;
        padding: 0.8em 1.2em;
        font-weight: 400;
        text-transform: capitalize;
    }

    .text-wrapper {
        display: flex;
        align-items: center;
    }

    .title-container {
        margin-top: 1em;

        .title {
            letter-spacing: 2px;
            // color: #ff5206;
            color: $COLOR_PRIMARY;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    .price-container {
        .currency {
            color: $COLOR_BG;
            font-size: 1.7em;

            @media screen and (max-width: 1780px) {
                font-size: 1.5em;
            }

            @media screen and (max-width: 1680px) {
                font-size: 1.3em;
            }

            @media screen and (max-width: 1580px) {
                font-size: 1.2em;
            }
        }

        .currency-value {
            font-size: 1.7em;
            color: $COLOR_BG;

            @media screen and (max-width: 1780px) {
                font-size: 1.5em;
            }

            @media screen and (max-width: 1680px) {
                font-size: 1.3em;
            }

            @media screen and (max-width: 1580px) {
                font-size: 1.2em;
            }
        }

        .separator-icon {
            height: 5px;
            width: 39px;
            background: $COLOR_BG;
            transform: rotate(119deg);
            margin-top: 25px;

            @media screen and (max-width: 1680px) {
                height: 5px;
                width: 25px;
                margin-top: 17px;
            }
        }
    }

    .tagline {
        margin-top: 14px;
        min-height: 66px;
    }

    .tagline-text {
        font-size: 0.8em;
        color: #86868f;
        font-weight: 500;
    }

    .card-divider {
        background-color: #86868f;
        border-width: 2px;
    }

    .get-started-btn {
        margin: 1.5em 0px;
        width: 100%;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        border: 1px solid rgb(228 228 237);
        border-radius: 26px;
        color: $COLOR_BG;
        font-weight: 800;
        text-transform: capitalize;
        font-size: 0.7em;
    }

    .content-icon {
        width: 31px;
        height: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        margin-right: 10px;
        border-radius: 50%;
    }

    .content-success {
        color: #0cae6a;
        background-color: #b3fbdd;
    }

    .content-error {
        background-color: #ffdccc;
        color: #ff4e00;
    }

    .card-body {
        .content {
            color: $COLOR_BG;
            font-size: 0.8em;
        }
    }
}

@media (min-width: 320px) and (max-width: 676px) {
    .list-card-parent.MuiBox-root.css-1g04awl {
        flex-direction: column;
    }

    .list-card.MuiBox-root.css-6b6wub {
        width: 100% !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .list-card-parent.MuiBox-root.css-1g04awl {
        flex-wrap: wrap;
    }

    .list-card.MuiBox-root.css-6b6wub {
        width: 47%;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .list-card-parent.MuiBox-root.css-1g04awl {
        flex-wrap: wrap;
    }

    .list-card.MuiBox-root.css-6b6wub {
        width: 47%;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .list-card-parent.MuiBox-root.css-1g04awl {
        flex-wrap: wrap;
    }

    .list-card.MuiBox-root.css-6b6wub {
        width: 47%;
    }
}

span.MuiTypography-root.MuiTypography-p.content.css-11nmrba-MuiTypography-root {
    width: 80%;
}

.expand-details-card {
    margin-top: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
        width: 25px;
        height: 25px;
        border: 1.5px solid;
        border-radius: 20px;
        margin-left: 10px;
        &:hover {
            color: $COLOR_WHITE;
            background: $COLOR_PRIMARY;
            border-color: $COLOR_PRIMARY;
        }
    }
}
