
          $font-path: "C:\Users\pc\Documents\new\spodiff-github\spotdif\src\assets\fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.spotdif-sidebar {
    .close-sidebar {
        background-color: #fff;
    }

    .MuiDrawer-paper {
        margin: 0;
        border-radius: 0;
        background: $COLOR_BG !important;
        height: 100vh;
    }

    .sidebar-logo-header {
        justify-content: space-between;
        align-items: center;
        padding: 32px 20px 28px;
    }

    .sidebar-toggle-action {
        .toggle-side-nav {
            display: flex;
            padding: 0;

            & > img {
                width: 32px;
            }
        }
    }

    .sidebar-route-list {
        a {
            li {
                padding-left: 0;
                margin-bottom: 10px;

                & > div {
                    padding: 6px 16px;
                    border-radius: 30px;
                    background: transparent;
                    margin: 0px;
                    margin-right: 5px;
                }

                span {
                    font-size: 16px;
                }
            }

            &.active,
            &:hover,
            &.active:hover {
                li > div {
                    background: $COLOR_PRIMARY;
                }
            }
        }
        a.nested-links-wrapper {
            &:hover {
                li > div {
                    background: none !important;
                }
            }
        }
    }

    .sidebar-menu-logged-wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        overflow: auto;
        height: 100%;
        ul {
            width: 100%;
        }

        .logged-user-wrapper {
            width: 100%;

            .logged-user-info {
                text-align: center;
                // background: #171748 0 0 no-repeat padding-box;
                background: #000e36 0 0 no-repeat padding-box;
                border-radius: 10px;
                margin: 10px;
                opacity: 1;
                padding: 12px 0;

                p {
                    margin: 0;
                    font-size: 18px;
                    padding-left: 0;
                    display: block;
                    width: 100%;
                }
            }
        }
        .logout-button {
            padding: 9px 16px;
            border-radius: 30px;
            margin: 10px 16px;
            width: auto;
            cursor: pointer;
            &:hover {
                background: $COLOR_PRIMARY;
            }
        }
    }

    .close-side-nav {
        position: absolute;
        top: 35px;
        right: 36px;
        z-index: 10;
        padding: 0;
        color: red;
        width: 24px;
    }
}
.sidebar-menu-logged-wrapper::-webkit-scrollbar {
    width: 8px;
}

.sidebar-menu-logged-wrapper::-webkit-scrollbar-track {
    background: #000032;
    border-radius: 10px;
}

.sidebar-menu-logged-wrapper::-webkit-scrollbar-thumb {
    background-color: #a7a7ba;
    border-radius: 10px;
    border: 3px solid #000032;
}
