
          $font-path: "C:\Users\pc\Documents\new\spodiff-github\spotdif\src\assets\fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.chart-container {
    .chart-wrapper {
        margin-bottom: 15px;

        &:last-child {
            padding-left: 15px;
            padding-right: 0px;
            .chart-date-filter {
                right: 15px;
            }
            @media screen and (max-width: 767px) {
                padding-left: 0;
            }
        }
        &:first-child {
            padding-right: 15px;
            padding-left: 0px;

            .chart-date-filter {
                right: 30px;
            }
            @media screen and (max-width: 767px) {
                padding-right: 0;
                .chart-date-filter {
                    right: 15px;
                }
            }
        }

        .chart-title-box {
            background: $COLOR_BG;
            border-top-left-radius: 0.75rem;
            border-top-right-radius: 0.75rem;
            padding: 13px 25px;

            h6 {
                color: #fff;
                font-weight: 400;
            }
        }
        .chart-graph-box {
            height: 100%;
        }
        canvas {
            height: 315px !important;
        }
    }
}
