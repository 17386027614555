
          $font-path: "C:\Users\pc\Documents\new\spodiff-github\spotdif\src\assets\fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.table_header {
    display: flex;
    padding-bottom: 10px;
    justify-content: space-between;
    flex-wrap: wrap;

    .table_header-left {
        // background-color: #ffffff;
        border: 1px solid #b2b3c3;
        border-radius: 3rem;
        padding-right: 0.4rem;
        margin-bottom: 10px;
        @media screen and (max-width: 768px) {
            width: 100%;
            border: 0;
            background: #fff;
            padding: 0;
            border-radius: 20px;
        }

        .table_header-grid {
            grid-column-gap: 0.4rem;
            grid-row-gap: 0.4rem;
            grid-template-rows: auto;
            grid-template-columns: 1.25fr 1fr 1fr 1fr;
            grid-auto-columns: 1fr;
            display: flex;
            overflow: hidden;
            border-radius: 20px;

            @media screen and (max-width: 768px) {
                border-radius: 15px;
                padding: 10px 2px;
            }
            .table_header-grid-fixed {
                display: flex;
                @media screen and (max-width: 768px) {
                    flex-wrap: wrap;
                    width: 100%;
                }
            }

            .table_header-col {
                padding-left: 0.6rem;
                padding-right: 30px;
                align-self: center;
                border-left: 1px solid #b2b3c3;

                @media screen and (max-width: 768px) {
                    width: 50%;
                    padding: 0 8px;
                    border-left: 0;
                }

                .table-input {
                    background-color: transparent;

                    @media screen and (max-width: 768px) {
                        border-bottom: 1px solid #000030;
                        border-radius: 0;
                        padding: 0 0 6px;
                    }
                    // border-radius: 30px;

                    &:focus {
                        background: transparent;
                    }
                }
            }
            .table_header-col:first-child {
                border-left: 0px !important;
            }
        }
    }

    .table_header-right {
        margin-left: auto;
    }
}
.full-width {
    width: 100% !important;
}

.text-bold {
    font-weight: 700;
}
.filters-bold {
    color: #00002f !important;
    font-weight: 600 !important;
    font-size: 0.9rem !important;
}

.placeholder-bold::placeholder {
    color: #00002f !important;
    font-weight: 600 !important;
    font-size: 0.9rem !important;
}
