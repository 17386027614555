
          $font-path: "C:\Users\pc\Documents\new\spodiff-github\spotdif\src\assets\fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.layout-middle-admin {
    padding-left: 3.5%;
    padding-right: 3.5%;
    // padding-top: 1rem;
    padding-bottom: 2rem;

    @media screen and (max-width: 1199px) {
        padding-left: 25px;
        padding-right: 25px;
    }
    @media screen and (max-width: 568px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    @media screen and (max-width: 480px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.sub-admin-table {
    .table-v2-block {
        padding: 0px;

        & > div {
            height: 83vh;
        }
    }

    // @fixme just create new common header for the v2 theme as now we are using v1 common header so just using this hack.
    .table_key-right {
        position: relative;

        .table_key-right-grid {
            // margin-bottom: 10px;
            position: absolute;
            right: 0px;
            top: -92px;
            margin-top: 30px;
        }
    }
}

.delete-icon {
    svg {
        color: $DANGER_COLOR !important;
        height: 22px !important;
        width: 22px !important;
    }
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.button.icon-button.mt-1.admin-screen-add-button {
    display: flex;
}

@media screen and (max-width: 768px) {
    #table-row-admin {
        #table-data-admin {
            padding: 14px 26px !important;
            text-align: center !important;
        }

        .data-labels {
            width: 0px;
        }
    }
}

@media screen and (max-width: 768px) {
    .admin-table-responsive {
        border-bottom: 8px solid #fff;

        .responsive-delete-icon {
            display: flex;
            justify-content: center;
        }

        .responsive-switch-icon {
            display: flex;
            justify-content: center;
        }
    }

    .sub-admin-table .table_key-right {
        margin-bottom: 3rem;
    }
}

.break-all {
    word-break: break-all;
    white-space: normal;
}

.mr-3 {
    margin-right: 1rem;
}

.edit-icon {
    color: #ff5d17;
}
.cursor-pointer {
    cursor: pointer;
}

.roleModalCloseWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .roleModalCloseBtn {
        opacity: 1;
        background-color: #fff;
        border-radius: 50%;
        height: 42px;
        width: 42px;
        min-width: 42px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 5px;
        svg {
            color: #000;
        }
    }
}
