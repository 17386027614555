
          $font-path: "C:\Users\pc\Documents\new\spodiff-github\spotdif\src\assets\fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.form-upper {
    .spotdif-outlined-field,
    .address-input {
        width: 33% !important;
    }

    .full-width-field {
        width: 66% !important;
        .spotdif-outlined-field {
            width: 100% !important;
        }
    }

    .buyer-answer-container {
        width: 66%;
    }

    @media screen and (max-width: 1200px) {
        .spotdif-outlined-field,
        .address-input,
        .dropdown-input {
            width: 50% !important;
        }

        .full-width-field {
            width: 100% !important;
            .spotdif-outlined-field {
                width: 100% !important;
            }
        }

        .buyer-answer-container {
            width: 100%;
        }
    }
    @media screen and (max-width: 768px) {
        .spotdif-outlined-field,
        .address-input,
        .dropdown-input {
            width: 100% !important;
        }

        .full-width-field {
            width: 100% !important;
            .spotdif-outlined-field {
                width: 100% !important;
            }
        }

        .buyer-answer-container {
            width: 100%;
        }
    }
}

.form-col-2 {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;

    .form-input-label {
        position: absolute;
        right: 15px;
        top: 2px;
        font-size: 14px;
        cursor: pointer;
        opacity: 1;

        @media screen and (max-width: 480px) {
            position: relative;
            left: 8px;
            right: inherit;
            top: -22px;
        }
    }
}

.business-logo-hrs {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .form-group > label {
        margin-left: 0px;
    }
}

// TODO: Remove post design consultation
.dropzone {
    margin: 1rem 0rem;
}

.preview-image {
    margin-top: 15px;
}

.form-input-groups {
    .googleapi {
        height: 44px;
        padding: 12px 19px !important;
        border: 1px solid #e4e6e9;
        color: #495057;
        outline: 0;

        &:focus-within,
        &:focus,
        &:visited {
            border: 2px solid #ff9566;
        }
    }
}

.upload-image-icon {
    margin: 0px 1rem;
}

.uploaded-image {
    position: relative;
    width: 90px;

    img {
        text-align: center;
        margin-top: 2px;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        object-fit: cover;
    }

    .deleteIcon {
        position: absolute;
        top: 8px;
        right: 6px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: $COLOR_BG;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }

        svg {
            color: #fff;
            width: 16px;
            height: 16px;
            margin-right: 0;
        }
    }
}

.dropdown {
    height: 50px;
}

.form-input-groups {
    padding: 0 10px;
}

.m-3 {
    margin: 1.5rem 0rem;
}

.input-adornment-text {
    color: #000000;
    font-size: 14px;
}

.input-adornment-symbol {
    color: $GRAY-1;
    margin-left: 10px;
    font-size: 23px;
    font-weight: 100;
}

.p-1 {
    padding-bottom: 2rem !important;
}

.disabled-input-with-icon {
    //background-color: $COLOR_WHITE-SHADE_1 !important;
    pointer-events: auto !important;
}
.serviceDetailsWrapper {
    @media screen and (max-width: 480px) {
        gap: 20px;
    }
    .serviceDetailsTextInput {
        margin-top: 1.4rem;
    }
}
