
          $font-path: "C:\Users\pc\Documents\new\spodiff-github\spotdif\src\assets\fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.brand-nav-wrapper {
    box-shadow: 0px 0px 36px rgba(0, 0, 0, 0.0392156863);
    position: sticky;
    top: 20px;
    width: 100%;
    z-index: 99;
    // RESPOSNIVE MENU
    .responsive-menu {
        position: absolute;
        width: calc(100% - 20px);
        margin: 0 10px;
        background: white;
        top: 44px;
        padding: 18px 0;
        z-index: 1;
        & > div {
            padding: 5px 18px;
            span,
            span svg {
                width: 20px;
                height: 20px;
            }
            &.trusworthy-box-responsive {
                font-size: 14px;
                color: #000032;
                display: flex;
                align-items: center;
                padding-top: 10px;
                img {
                    margin-right: 10px;
                    width: 115px;
                }
                & > span {
                    margin-left: auto;
                    svg {
                        color: #1acbb1;
                    }
                }
            }
            &:first-child {
                border-top: 1px solid #ddd;
                padding-top: 15px;
            }
            a {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;
                font-size: 16px;
            }
        }
    }
    .nav-actions {
        position: relative;
        top: 2px;
        & > div {
            height: 26px;
            // &:first-child {
            //     border-right: 1px solid #e0e0e0;
            // }
        }

        .hamburger-menu {
            display: none;
            padding: 0;
            height: 26px;
            min-width: auto;
            cursor: pointer;
            @media screen and (max-width: 768px) {
                display: block;
            }
        }
        .cross-menu {
            cursor: pointer;
        }
        .trusworthy-box {
            display: flex;
            align-items: center;
            position: relative;
            top: -2px;
            padding-right: 24px;
            @media screen and (max-width: 768px) {
                display: none;
            }
            img {
                width: 120px;
            }
            & > span {
                margin-left: 6px;
                svg {
                    width: 14px;
                    height: 14px;
                    position: relative;
                    top: -4px;
                    fill: #04da8d;
                    color: #04da8d;
                }
            }
        }
        .trusworthy-box-footer {
            img {
                width: 100px;
            }
        }
        .sign-in,
        .create-account {
            @media screen and (max-width: 768px) {
                display: none;
            }

            .spotdif-arrow {
                color: $COLOR_PRIMARY;
            }

            a {
                color: $DARK_BLUE;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                display: flex;
                align-items: center;
                transition: all 0.3s;
                &:hover {
                    opacity: 0.6;
                    & > span {
                        color: #04da8d;
                    }
                }

                & > span {
                    margin-left: 8px;
                    color: #000032;
                    svg {
                        width: 14px;
                        height: 14px;
                        position: relative;
                        top: -1px;
                    }
                }
            }
        }
        .create-account {
            a {
                & > span {
                    color: #ff4500;
                }
            }
        }
    }
}
.nav-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    padding: 10px 40px;
    margin: 0 20px 0 20px;
    position: relative;
    z-index: 10;
    .logo-wrapper {
        display: flex;
        .main-logo {
            width: 116px;
            height: 52px;
        }
    }
    &.nav-items-bottom {
        @media screen and (max-width: 768px) {
            padding-left: 0;
            padding-right: 0;
        }
        .nav-actions {
            & > div {
                @media screen and (max-width: 768px) {
                    padding: 0 30px;
                    a {
                        text-align: center;
                    }
                }
                @media screen and (max-width: 580px) {
                    padding: 0 20px;
                }
            }
            .terms,
            .privacy {
                position: relative;
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 9px;
                    .spotdif-arrow {
                        margin-bottom: 4px;
                        color: $COLOR_PRIMARY;
                    }
                }
                // &:after {
                //     content: '';
                //     position: absolute;
                //     right: 0;
                //     margin: 0 auto;
                //     background: #5c6888;
                //     width: 1px;
                //     height: 20px;
                //     z-index: 10;
                //     top: 2px;
                // }
            }
            @media screen and (max-width: 768px) {
                justify-content: center;
                width: 100%;
                gap: 0;
            }
        }
        .logo-wrapper {
            @media screen and (max-width: 768px) {
                display: none;
            }
        }
    }
    @media screen and (max-width: 768px) {
        margin: 10px;
        padding: 18px 20px;
        img {
            width: 92px;
        }
    }
}
.nav-items-top {
    background-color: #fff;
}
.nav-items-bottom {
    background-color: $COLOR_BG;
    color: #fff;
}
.nav-actions {
    display: flex;
    align-items: center;
    gap: 35px;
    @media screen and (max-width: 1480px) {
        gap: 15px;
    }
}
.signin-arrow {
    margin-left: 3px;
}
.terms,
.privacy {
    a {
        color: #fff;
        font-size: 16px;
    }
}
.terms.-mt-1,
.privacy.-mt-1 {
    margin-top: -0.5rem;
}

.responsive-menu {
    .social-media {
        display: flex;
        align-items: center;
        margin-top: 8px;
        .social-media-icon {
            img {
                width: 20px;
                height: 20px;
            }
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #122d71;
            border-radius: 50%;
            color: #fff;
            margin-left: 20px;
            width: 32px;
            height: 32px;
        }
    }
}
.border-for-socials {
    border: 1px solid #ddd;
    width: 90%;
    margin: 8px auto 0px;
}
