
          $font-path: "C:\Users\pc\Documents\new\spodiff-github\spotdif\src\assets\fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.text-center {
    text-align: center !important;
}

.lead-quantity-wrap {
    display: flex;
    gap: 12px;
    align-items: center;

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: center;
    }

    .lead-quantity-input,
    .lead-quantity-cost {
        width: 43%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;

        .full-width-field {
            margin-bottom: 0;
            .lead-mail-text {
                color: $GRAY-1;
                margin-top: 10px;
                font-size: 13px;
                line-height: 20px;
                font-weight: 300;
                margin-bottom: 0px;
                a {
                    text-decoration: underline !important;
                }
            }
        }

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    .lead-quantity-input {
        .spotdif-field {
            & > label {
                margin-bottom: 10px;
            }
        }

        input {
            text-align: center;
        }

        p {
            text-align: left;
            margin-bottom: 20px;
        }
    }

    .lead-quantity-cost {
        background-color: #142f73;
        color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.161);
        display: flex;
        min-height: 165px;
        padding: 36px 50px;

        @media screen and (max-width: 991px) {
            padding: 40px 25px;
        }

        @media screen and (max-width: 768px) {
            padding: 25px;
        }

        ul {
            padding-left: 0;
            list-style: none;
            margin: 0;
            display: flex;

            @media screen and (max-width: 576px) {
                flex-wrap: wrap;
            }

            li {
                &:last-child {
                    border-left: 1px solid #fff;
                    padding-left: 20px;

                    @media screen and (max-width: 576px) {
                        border-left: 0;
                        padding-left: 0;
                    }
                }

                &:first-child {
                    padding-right: 20px;
                    padding-left: 0;

                    @media screen and (max-width: 576px) {
                        padding-right: 0;
                    }
                }

                label {
                    color: #fff;
                    font-weight: 500;
                    font-size: 16px;
                    display: block;
                    min-height: 30px;
                    line-height: 20px;
                    margin-bottom: 16px;

                    @media screen and (max-width: 1366px) {
                        margin-bottom: 10px;
                    }

                    @media screen and (max-width: 576px) {
                        min-height: auto;
                    }
                }

                p {
                    font-size: 20px;
                    font-weight: 600;

                    span {
                        color: #9fa1b3;
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .card-wrapper-light {
        width: 50%;
        background-color: $COLOR_PRIMARY;
        border-radius: 10px;
        display: flex;
        padding: 49px;
        align-items: center;
        justify-content: space-around;
        min-height: 204px;
    }
    .card-wrapper-dark {
        width: 50%;
        background-color: $COLOR_BG;
        border-radius: 10px;
        display: flex;
        padding: 49px;
        align-items: center;
        justify-content: space-around;
        min-height: 204px;
    }
}

.leadSettingsCardWrapper {
    margin-top: 40px;

    .lead-area-map {
        svg {
            margin-top: 0 !important;
        }

        .map-legend {
            padding-right: 10px;
            height: auto;
            width: 172px;
            padding-bottom: 10px;
            top: 10px;
            right: 10px;

            .colorBox {
                width: 25px;
                height: 25px;
            }

            .text {
                color: rgb(52, 71, 103);
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}

.post-code-picker {
    position: relative;
    border: 1px solid #ddd;
    display: flex;
    // height: 600px;
    // width: 600px;

    & > svg {
        height: 600px;
    }
    & > svg.radius-map-composable {
        width: 100%;
    }

    @media screen and (max-width: 576px) {
        & > svg {
            height: 300px !important;
            width: 100% !important;
        }
    }

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
    }

    svg {
        @media screen and (max-width: 768px) {
            order: 2;
        }
    }

    .map-legend {
        @media screen and (max-width: 768px) {
            position: relative;
            order: 1;
            left: 10px !important;
            width: calc(100% - 20px) !important;
            margin-bottom: 12px;

            .colorBox {
                width: 20px;
                height: 20px;
            }
        }
    }

    .controls {
        position: absolute;
        bottom: 0;
        right: 6px;

        button {
            border: 0;
            background: $COLOR_PRIMARY;
            color: #fff;
            margin: 0 3px;
            width: 26px;
            height: 26px;
            cursor: pointer;

            svg {
                width: 15px;
            }
        }
    }
}
.radius-map {
    justify-content: center;
}

.leadAreaTabPostalSelect {
    h4 {
        font-size: 22px;
    }

    .map-collapse-data {
        display: flex;
        border-bottom: 1px solid #ddd;
        align-items: center;
        padding: 6px 0;

        .map-collapse-dropdown {
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 480px) {
                padding-right: 12px;
            }
            & > span {
                @media screen and (max-width: 480px) {
                    font-size: 14px;
                }
            }

            .material-icons-round {
                padding-top: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .map-collapse-controls {
        margin-left: auto;
        display: flex;

        & > span {
            display: block;

            @media screen and (max-width: 480px) {
                font-size: 14px;
                padding-right: 14px;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }
}

.leadSettingsHrsWrapper {
    margin-top: 40px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.leadAreaTab {
    & > .MuiBox-root {
        @media screen and (max-width: 991px) {
            flex-wrap: wrap;
        }
    }

    .lead-area-map,
    .leadAreaTabPostalSelect {
        @media screen and (max-width: 991px) {
            width: 100% !important;
        }
    }
}

.lead-area-text {
    color: $COLOR_BG;
    margin-top: -10px;
    padding-bottom: 22px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
}

.pt-3 {
    padding-top: 1.5rem;
}

.justify-space-between {
    justify-content: space-between;
}

.text-color-orange {
    color: $COLOR_PRIMARY;
    cursor: pointer;
}

@media (min-width: 320px) and (max-width: 767px) {
    .lead-area-text-wrapper {
        flex-direction: column;
    }
}

// .leadAreaTabPostalSelect {
//     div{
//         margin-left:5%
//     }
// }
